<template>
    <v-dialog v-model="isOpen" max-width="400">
        <v-card>
            <v-card-title class="dialogText">
                <v-row class="text-center">
                    <v-col cols="12" v-if="!isAnswerError">
                        QRコードを読み取ってください
                    </v-col>
                    <v-col cols="12" v-else>
                        これは違う<br />
                        QRコードみたい…。<br />
                        他の場所を探してみよう！
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text class="dialogText">
                <v-row class="text-center" v-if="!isAnswerError">
                    <v-col cols="12" class="pa-0 pt-3">
                        <v-col cols="12" class="pa-0">
                            <canvas ref="capCamera" width="240px" height="240px"></canvas>
                        </v-col>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                        <v-btn width="80%" class="btn_blue" @click="isOpen = false">閉じる</v-btn>
                    </v-col>
                </v-row>
                <v-row class="text-center" v-else>
                    <v-col cols="12" class="pt-5">
                        <v-btn width="80%" @click="viewQrReader()" class="btn_orange">もう一度読む</v-btn>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                        <v-btn width="80%" class="btn_blue" @click="isOpen = false">閉じる</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data: () => ({
        isOpen: true,
        // qrTypeError: false,
        isAnswerError: false
    }),
    props: {
    },
    created: function () {
        this.viewQrReader();
    },
    destroyed: function () {
        this.$stamp.stopCamera();
    },
    methods: {
        async viewQrReader(count) {
            this.isAnswerError = false;
            if (!count) count = 1;
            if (this.$refs.capCamera) {
                try {
                    await this.$stamp.qrCamera(this.$refs.capCamera, (event) => {
                        // 指定のQRを探す
                        // console.log("QE", event);

                        // console.log("readQrs", this.$store.getters.qrcode);
                        // console.log("readQrs1", event.data, this.$store.getters.qrcode[event.data]);
                        this.$stamp.stopCamera();

                        try {
                            if (this.$store.getters.qrcode[event.data]) {
                                const readQrs = this.$store.getters.qrcode[event.data];
                                let okQr = false;
                                for (let readQr of readQrs) {
                                    if (readQr.type == "mission" && readQr.chapter == this.nowMissionChapter && readQr.section == this.nowMissionSection) {
                                        okQr = true;
                                    }
                                }
                                if (okQr) {
                                    // this.$stamp.stopCamera();
                                    this.qrTypeError = false;
                                    this.$emit('correct');
                                } else {
                                    gtag('event', 'qrmiss', { 'event_category': this.nowMission, 'event_label': event.data });
                                    throw new Error("no hit qr")
                                }
                            } else {
                                gtag('event', 'qrmissother', { 'event_category': this.nowMission, 'event_label': event.data });
                                throw new Error("other qr")
                            }
                        } catch (error) {
                            // this.$stamp.stopCamera();
                            // this.qrTypeError = true;
                            this.isAnswerError = true;
                        }
                    })
                } catch (error) {
                    console.error(error);
                    this.$router.push({ name: 'error', params: { 'errormessage': "カメラなどのデバイスの利用ができません。" } });
                }
            } else if (count < 5) {
                setTimeout(() => {
                    this.viewQrReader(count + 1)
                }, 300);
            }
        },
    },
    computed: {
        nowMissionChapter() {
            return this.$store.getters.nowMissionChapter;
        },
        nowMissionSection() {
            return this.$store.getters.nowMissionSection;
        },
        nowMission() {
            return this.$store.getters.nowMissionChapter + "-" + this.$store.getters.nowMissionSection;
        }
    },
    watch: {
        isOpen(newVal) {
            if (!newVal) {
                this.$emit('close');
                this.$stamp.stopCamera();
            }
        }
    }
}
</script>