<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center">
        <img src="/images/yune_title_mission6.png" width="70%" />
      </v-col>
      <v-col cols="12" class="text-left pl-12 pr-12 text-m6">
        ん？お化け図鑑が光っている？恐る恐る開いてみるとジズリからの手紙があった。<br />
        おばけたちは映画の撮影の後、癒されにここに来ていたみたいだ。<br />
        手紙には<span class="text-att-m6">コラボ風呂</span>でおばけたちが仲良さそうに写っている写真と変な文章が書かれていた…
      </v-col>
      <v-col cols="12" class="text-center">
        <v-img src="/images/mission6_message.jpg" />
      </v-col>
      <v-col cols="12" class="text-center pl-6 pr-6 text-m6" style="font-weight: bold;">
        <img src="/images/mission6_aktext.png" />の暗号を読み解け！
      </v-col>
    </v-row>

    <v-row class="text-center pl-8 pr-8"
      v-if="nowMissionChapter < 6 || nowMissionChapter == 6 && nowMissionSection <= 2">

      <v-col cols="6" class="pt-0">
        <v-btn width="100%" class="btn_orange" @click="viewDialogS1 = true">答える</v-btn>
      </v-col>
      <v-col cols="6" class="pt-0">
        <v-btn width="100%" :class="$store.getters.useHintList.indexOf(nowMission) == -1 ? 'btn_blue' : 'btn_orange'"
          @click="s1Hint">ヒントを聞く</v-btn>
      </v-col>
    </v-row>
    <v-row class="text-center pl-6 pr-6" v-else>
      <v-col class="answerTitle text-m6">
        答え
      </v-col>
      <v-col cols="9" class="answerArea mr-7">
        みんなありがとう
      </v-col>
    </v-row>

    <div class="text-center pa-10">
      <v-btn width="80%" class="btn_orange" @click="$router.push('/map');">エリアマップへ</v-btn>
    </div>

    <!---------------------->

    <InputWindow v-if="viewDialogS1" :answerTexts="['みんなありがとう']" @close="viewDialogS1 = false"
      :hintBtnColor="$store.getters.useHintList.indexOf(nowMission) == -1 ? 'blue' : 'orange'" @hint="s1Hint"
      @correct="s1AnswerOk">
      <template v-slot:question>
        <img src="/images/mission6_aktext.png" />の暗号を読み解け！
      </template>
      <template v-slot:hint>
        ヒントを聞く
      </template>
    </InputWindow>
    <!---------------------->

    <v-dialog v-model="viewDialogS2" max-width="400">
      <v-card>
        <v-card-text class="dialogText">
          <v-row class="pt-10">
            <v-col cols="12">
              <v-img src="/images/mission6_present.jpg"></v-img>
            </v-col>
            <v-col cols="12" class="pt-0 text-center">
              <v-btn width="80%" class="btn_orange" @click="goPresent">プレゼント
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!---------------------->

    <HelpWindow :message="hintMessage" @close="hintMessage = null"></HelpWindow>

    <!---------------------->
    <!-- <div v-if="$store.getters.isMaintenance">
      <v-btn @click='$store.commit("setNextMission", 6); $store.commit("setMissionSection", 1)'>1</v-btn>
      <v-btn @click='missionClear()'>END</v-btn>
    </div> -->

  </v-container>
</template>
<style scoped>
.text-m6 {
  color: black;
}

.text-att-m6 {
  color: #814876;
  font-weight: bold;
}
</style>
<script>
import HelpWindow from "./components/HelpWindow";
import InputWindow from "./components/InputWindow";

export default {
  components: {
    HelpWindow,
    InputWindow
  },
  data: () => ({
    viewDialogS1: false,
    viewDialogS2: false,
    hintMessage: null
  }),
  created: function () {
    if (this.nowMissionChapter < 6) {
      this.$router.push('/');
    } else if (this.nowMissionChapter == 6 && (this.nowMissionSection == 0 || this.nowMissionSection == 1)) {
      this.$router.push('/map');
    }

  },
  destroyed: function () {
    let appDom = document.getElementById("app");
    appDom.classList.remove("goziBg");
  },
  methods: {
    s1AnswerOk() {
      this.viewDialogS1 = false;
      this.missionClear();
    },
    missionClear() {
      this.viewDialogS2 = true;
      this.$store.commit("setNextMission", 9);
    },
    s1Hint() {
      this.hintMessage = "コラボ風呂に行くと、お化けたちが何か言っているよ。<br/>例えば山彦は「ストレスが抜ける」と言っているね。手紙の山彦の行から「ストレス」を抜いてみよう！他のおばけも同じようにしてみよう。<br/>矢印の下は４行目の雲梯が書いてある言葉をもう一度読んでみよう。<br/>そして、この紙はなんだったかな。"
    },
    goPresent() {
      //      this.$router.push({ "name": "present", "hash": "sticker" });
      // this.$router.push("/present#sticker");
      this.$router.push({ name: 'present', params: { 'go': 'sticker' } });

    }
  },
  computed: {
    nowMissionChapter() {
      return this.$store.getters.nowMissionChapter;
    },
    nowMissionSection() {
      return this.$store.getters.nowMissionSection;
    },
    nowMission() {
      return this.$store.getters.nowMissionChapter + "-" + this.$store.getters.nowMissionSection;
    }
  },
  watch: {}
};
</script>
