<template>
  <v-container>

    <v-row class="text-center">
      <v-col cols="12">
        <img src="/images/yune_title_mission1.png" width="70%" />
      </v-col>
      <v-col cols="12" class="text-left pl-10 pr-10">
        支配人の言っていた場所へ来た。そこは広く見晴らしの良い<span class="text-att">超絶景展望露天風呂</span>がある。とてもいい眺めだ。<br />
        「こんなところにおばけがいるのか？」そう、目の前に広がる絶景に向かって言うと同じ言葉が返ってきた…！でもそこには誰もいない。。。<br />
        一体どういうことだ？？ おばけの仕業なのか？？ ん、どうやら汚い言葉を叫ぶと、妙に怒りっぽい言葉で返ってくるぞ！
      </v-col>
    </v-row>

    <div class="nazoM">
      <div style="width:100%">
        <v-row class="text-center">
          <v-col cols="12" class="pa-0" style="font-weight: bold;">
            3人が同時に叫んだ。<br />
            自分が叫んだ言葉は？
          </v-col>
        </v-row>

        <v-row class="text-center" v-if="nowMissionChapter <= 1 && nowMissionSection <= 1">
          <v-col cols="12" class="pt-1 pb-1 pl-10 pr-10">
            <v-img :src='"/images/yune_mission1_1_q_" + s1qnum + ".png"'></v-img>
          </v-col>
          <v-col cols="6" class="pt-0">
            <v-btn width="100%" class="btn_orange" @click="s1StartRec">答える
            </v-btn>
          </v-col>
          <v-col cols="6" class="pt-0">
            <v-btn width="100%"
              :class="$store.getters.useHintList.indexOf(nowMission) == -1 ? 'btn_blue' : 'btn_orange'" @click="s2Hint">
              ヒントを聞く</v-btn>
          </v-col>
        </v-row>

        <v-row class="text-center" v-else>
          <v-col cols="12" class="pt-1 pb-1 pl-10 pr-10">
            <v-img src="/images/yune_mission1_1_q_0.png"></v-img>
          </v-col>
          <v-col class="answerTitle">
            答え
          </v-col>
          <v-col cols="9" class="answerArea">
            ばかもん・ぼけなす<br />・あほんだら
          </v-col>
        </v-row>
      </div>
    </div>

    <div v-if="nowMissionChapter > 1 || (nowMissionChapter == 1 && nowMissionSection >= 2)">
      <v-row class="text-center">
        <v-col cols="12" class="text-left pl-10 pr-10">
          答えを叫ぶと、「そんな汚い言葉を叫んでいるのは誰だ～！！」と声がして、山彦が現れた！
        </v-col>
        <v-col cols="12">
          <v-btn class="btn_orange" @click="dialogS2Movie = true">ムービーを見る</v-btn>
        </v-col>
      </v-row>

      <v-row class="text-center" v-if="nowMissionChapter > 1 || (nowMissionChapter == 1 && nowMissionSection >= 3)">
        <v-col cols="12" class="text-left pl-10 pr-10">
          山彦が気絶した！封印のチャンスだ！
        </v-col>
      </v-row>

      <div class="nazoS" v-if="nowMissionChapter > 1 || (nowMissionChapter == 1 && nowMissionSection >= 3)">
        <div style="width:100%">
          <v-row class="text-center">
            <v-col cols="12" class="pb-5" style="font-weight: bold;">
              山彦の特技は？
            </v-col>
          </v-row>
          <v-row class="text-center" v-if="nowMissionChapter == 1 && nowMissionSection == 3">
            <v-col cols="6" class="pt-0">
              <v-btn width="100%" class="btn_orange" @click="dialogS2Answer = true">答える</v-btn>
            </v-col>
            <v-col cols="6" class="pt-0">
              <v-btn width="100%" @click="dialogS2Movie = true" class="btn_blue">もう一度見る</v-btn>
            </v-col>
          </v-row>
          <v-row class="text-center" v-else>
            <v-col class="answerTitle">
              答え
            </v-col>
            <v-col cols="9" class="answerArea">
              はねかえし
            </v-col>
          </v-row>
        </div>
      </div>
    </div>

    <div class="text-center pa-10">
      <v-btn width="80%" class="btn_orange" @click="$router.push('/map');">エリアマップへ</v-btn>
    </div>

    <!-- <div v-if="$store.getters.isMaintenance">
      <v-btn @click='$store.commit("setNextMission", 1); $store.commit("setMissionSection", 1)'>1</v-btn>
      <v-btn @click='$store.commit("setNextMission", 1); $store.commit("setMissionSection", 2)'>2</v-btn>
      <v-btn @click='$store.commit("setNextMission", 1); $store.commit("setMissionSection", 3)'>3</v-btn>
      <v-btn @click='s2AnswerOk()'>END</v-btn>
    </div> -->

    <!------------------------>
    <v-dialog v-model="dialogS1" max-width="400" class="correctBase">
      <v-card>
        <v-card-title>
          <v-row class="text-center" style="font-weight: bold;">
            <v-col cols="12" v-if="recognitionView < 5">
              準備中。ちょっとまってね。
            </v-col>
            <v-col cols="12" v-if="recognitionView == 5">
              言葉を言って！
            </v-col>
            <v-col cols="12" v-if="recognitionView == 6">
              言葉を入力して！
            </v-col>
            <v-col cols="12" v-if="recognitionView == 9">
              残念！答えが<br />違っているようだ
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="dialogText">
          <v-row class="text-center">

            <v-col cols="12" class="voiceView" v-if="recognitionView == 0">
              &nbsp;
            </v-col>
            <v-col cols="12" class="voiceView countdownText" v-if="recognitionView == 1">
              ３
            </v-col>
            <v-col cols="12" class="voiceView countdownText" v-if="recognitionView == 2">
              ２
            </v-col>
            <v-col cols="12" class="voiceView countdownText" v-if="recognitionView == 3">
              １
            </v-col>

            <v-col cols="12" class="voiceView" v-if="recognitionView == 5"
              :style="{ 'background-image': 'url(/images/yume_voice_icon.png)' }">
              <div v-if="$store.getters.isMaintenance">
                {{ recognitionText }}
              </div>
            </v-col>

            <v-col cols="12" class="pt-1 pb-1 pl-20 pr-20" v-if="recognitionView == 6">
              <v-img :src='"/images/yune_mission1_1_q_" + s1qnum + ".png"'></v-img>
            </v-col>
            <v-col cols="12" class="pa-0 pt-3" v-if="recognitionView == 6">
              <v-text-field v-model="s1InputText" placeholder="ここに叫んだ言葉を入力" outlined>
              </v-text-field>
            </v-col>
            <v-col cols="12" class="pt-0" v-if="recognitionView == 6">
              <v-btn width="80%" @click="s1AnswerCheck" class="btn_orange">答える</v-btn>
            </v-col>
            <div class="correct" v-if="recognitionView == 6 && s1CorrectAnime">
              <v-img src="/images/correct.svg"></v-img>
            </div>

            <v-col cols="12" class="pt-5" v-if="recognitionView == 9">
              <v-btn width="80%" class="btn_orange" @click="s1Retry">もう一度答える</v-btn>
            </v-col>

            <v-col cols="12" class="pt-0">
              <v-btn width="80%" class="btn_blue" @click="dialogS1 = false">閉じる</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!------------------------>
    <MovieWindow v-if="dialogS2Movie" @close="dialogS2Movie = false" :movie="s2Video"></MovieWindow>
    <!------------------------>
    <InputWindow v-if="dialogS2Answer" :answerTexts="['はねかえし']" @close="dialogS2Answer = false"
      @hint="dialogS2Movie = true" @correct="s2AnswerOk">
      <template v-slot:question>
        山彦の特技は？
      </template>
      <template v-slot:hint>
        もう一度見る
      </template>
    </InputWindow>
    <!------------------------>
    <HelpWindow :message="hintMessage" @close="hintMessage = null"></HelpWindow>

  </v-container>



</template>
<style scoped>
.skipBtn {
  color: white;
}

.skipBtn::before {
  background-color: #555555;
}

.voiceView {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 230px;
  height: 103px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  /* background-image: url("/images/yume_voice_icon.png"); */
}


.countdownText {
  color: #e4007f;
  font-size: 6em;
  font-weight: bold;
}

.voiceError {
  color: #e4007f;
  font-size: 2em;
  font-weight: bold;
}

.correctBase {
  position: relative;
}

.correct {
  position: absolute;
  animation-name: correctfade;
  animation-duration: 1400ms;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  opacity: 0;
}

.correct>* {
  margin: 75px auto;
  width: 33%;
}

@keyframes correctfade {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}
</style>
<script>
import HelpWindow from "./components/HelpWindow";
import InputWindow from "./components/InputWindow";
import MovieWindow from "./components/MovieWindow";

export default {
  components: {
    HelpWindow,
    InputWindow,
    MovieWindow
  },
  data: () => ({
    dialogS1: false,
    dialogS2Movie: false,
    dialogS2Answer: false,
    s1qnum: 0,
    s1trycount: 0,
    s1InputText: "",
    s1CorrectAnime: false,
    s1CountdownTimer: null,
    s2Video: null,
    recognitionStatus: 0,
    recognitionView: 0,
    recognition: null,
    recognitionText: "",
    recognizer: null,
    hintMessage: null
  }),
  created: function () {
    if (this.nowMissionChapter < 1) {
      this.$router.push('/');
    } else if (this.nowMissionChapter == 1 && this.nowMissionSection == 0) {
      this.$router.push('/map');
    }

    // preload
    this.s2Video = document.createElement("video");
    this.s2Video.src = "movies/mission1_1.mp4";

    this.s1qnum = Math.floor(Math.random() * 3) + 1;

    // TEST
    // let okwords = {
    //   1: ["ばか", "バカ", "馬鹿"],
    //   2: ["ぼけ", "ボケ", "呆け", "木瓜", "惚け"],
    //   3: ["あほ", "アホ", "阿呆"],
    // };
    // console.log("s1qnum", this.s1qnum, okwords[this.s1qnum]);
    // TEST

    if (this.recognition) {
      this.recognition.close();
    }
    this.recognitionStatus = 0;
  },
  destroyed: function () {
    // console.log("mission 1 destroyed");
    if (this.recognition) {
      this.recognition.close();
    }
  },
  methods: {

    async startRecognition() {

      if (this.recognizer) {
        await this.recognizer.stopContinuousRecognitionAsync();
      }

      this.countdownView(2);

      try {
        let stream = await navigator.mediaDevices.getUserMedia({
          video: false,
          audio: true
        });

        const SpeechRecognition = window.SpeechSDK;
        // const speechConfig = SpeechRecognition.SpeechConfig.fromSubscription(process.env.VUE_APP_AZURE_SPEACH_KEY, "japaneast");
        const speechConfig = SpeechRecognition.SpeechConfig.fromSubscription('ad20ec8e95614ff9bb06e666f06abdb8', "japaneast");

        speechConfig.speechRecognitionLanguage = "ja-JP";
        // const audioConfig = SpeechRecognition.AudioConfig.fromDefaultMicrophoneInput();
        const audioConfig = await SpeechRecognition.AudioConfig.fromStreamInput(stream);

        this.recognizer = new SpeechRecognition.SpeechRecognizer(speechConfig, audioConfig);
        this.recognizer.recognizing = (s, e) => {
          // console.log("---------------------recognizing", e.result);
          this.recognitionStatus = 5;
          this.s1AnswerVoiceRec(e.result.privText);
        }
        this.recognizer.recognized = async (s, e) => {
          // console.log("=====================recognized", e.result);
          // console.log(e.result);
          this.recognitionStatus = 5;
          this.s1AnswerVoiceRec(e.result.privText);
        }
        this.recognizer.canceled = (s, e) => {
          // console.log("---------------------canceled ", e);
          this.recognitionStatus = 9;
          this.recognitionView = 9;
        };
        this.recognizer.sessionStarted = (s, e) => {
          // console.log("---------------------sessionStarted", e);
          this.recognitionStatus = 5;
        }
        this.recognizer.speechStartDetected = (s, e) => {
          // console.log("---------------------speechStartDetected", e);
          this.recognitionStatus = 5;
        }
        this.recognizer.sessionStopped = (s, e) => {
          // console.log("---------------------sessionStopped", e);
          this.recognitionStatus = 9;
          this.recognitionView = 9;
        }
        this.recognizer.recognizeOnceAsync();
      } catch (e) {
        console.log(e);
        clearTimeout(this.s1CountdownTimer);
        this.recognitionView = 6;
        this.s1trycount = 9;
        gtag('event', 'recognizererror', { 'event_category': e.message, 'event_label': this.s1qnum });

        // this.$router.push({ name: 'error', params: { 'errormessage': "カメラなどのデバイスの利用ができません。" } });
      }

    },

    countdownView(num) {
      if (!num) num = 1;
      // console.log("countdown", num);
      this.recognitionView = num;
      this.s1CountdownTimer = setTimeout(() => {
        if (num == 3) {
          if (this.recognitionStatus == 5) {
            this.recognitionView = 5;
          } else {
            this.countdownView(3);
          }
        } else {
          this.countdownView(num + 1);
        }
      }, 400);
    },

    s1StartRec() {
      this.recognitionStatus = 1;
      this.dialogS1 = true;
    },
    s1AnswerCheck() {
      let okwords = {
        1: "ばかもん",
        2: "ぼけなす",
        3: "あほんだら",
      };
      if (okwords[this.s1qnum] == this.s1InputText) {
        this.s1CorrectAnime = true;
        setTimeout(() => {
          this.s1AnswerOk();
        }, 1300);
      } else {
        this.recognitionView = 9;
        gtag('event', 'miss', { 'event_category': this.nowMission, 'event_label': this.s1InputText });
      }
    },

    s1AnswerVoiceRec(resWord) {
      if (!resWord) return;
      // console.log("s2AnswerVoiceRec", resWord);

      let okwords = {
        1: ["ばか", "ばっか", "バカ", "馬鹿"],
        2: ["ぼけ", "ボケ", "呆け", "木瓜", "惚け"],
        3: ["あほ", "アホ", "阿呆"],
      };

      this.recognitionText = resWord;
      for (let word of okwords[this.s1qnum]) {
        if (resWord.indexOf(word) >= 0) {
          this.s1AnswerOk();
          gtag('event', 'recognizerok', { 'event_category': resWord, 'event_label': this.s1qnum });
          break;
        } else {

        }
      }
    },
    s1AnswerOk() {
      this.$store.commit("setMissionSection", 2);
      this.dialogS1 = false;
      this.dialogS2Movie = true;
    },

    s2AnswerOk() {
      this.dialogS2Answer = false;
      this.$store.commit("setNextMission", 2);
      this.$router.push({ name: 'book', params: { 'seal': true } });
    },
    // s2PlayVideo(count) {
    //   if (!count) count = 0;
    //   if (this.$refs.s2MovieArea) {
    //     // console.log(this.$refs.s2MovieArea, this.s2Video);
    //     this.$refs.s2MovieArea.innerHTML = "";
    //     this.$refs.s2MovieArea.appendChild(this.s2Video);
    //     this.s2Video.currentTime = 0;
    //     this.s2Video.play();
    //   } else if (count < 10) {
    //     setTimeout(() => {
    //       this.s2PlayVideo(count + 1);
    //     }, 100)
    //   }
    // },
    async s1Retry() {
      if (this.recognizer) {
        await this.recognizer.stopContinuousRecognitionAsync();
      }
      this.recognitionStatus = 0;
      this.recognitionText = "";
      this.s1trycount++;
      if (this.s1trycount >= 3) {
        this.s1InputText = "";
        this.recognitionView = 6;
      } else {
        this.recognitionView = 0;
        this.startRecognition();
      }
    },
    s2Hint() {
      this.hintMessage = "超絶展望台風呂にある、文字が貼られた看板と見比べてみよう！<br />同じ色のところだけ読むと…… ";
    }
  },
  computed: {
    nowMissionChapter() {
      return this.$store.getters.nowMissionChapter;
    },
    nowMissionSection() {
      return this.$store.getters.nowMissionSection;
    },
    nowMission() {
      return this.$store.getters.nowMissionChapter + "-" + this.$store.getters.nowMissionSection;
    }
  },
  watch: {
    async dialogS1(newVar) {
      // console.log("dialogS1", newVar);
      if (newVar) {
        this.s1trycount = 0;
        this.recognitionStatus = 0;
        this.recognitionView = 0;
        this.startRecognition();
      } else {
        if (this.recognizer) {
          await this.recognizer.stopContinuousRecognitionAsync();
        }
        this.recognitionStatus = 0;
        this.recognitionView = 0;
        this.recognitionText = "";
      }
    },
    dialogS2Movie(newVar) {
      if (newVar) {
        this.dialogS2Answer = false;
      } else {
        if (this.nowMissionChapter == 1 && this.nowMissionSection >= 2) {
          this.dialogS2Answer = true;
          this.$store.commit("setMissionSection", 3);
        }
      }
    },
    recognitionStatus(newVar) {
      if (newVar == 5) {
        gtag('event', 'recognizerstart', { 'event_category': undefined, 'event_label': this.s1qnum });
      } else if (newVar == 9) {
        gtag('event', 'recognizermiss', { 'event_category': this.recognitionText, 'event_label': this.s1qnum });
      }
    },
    recognitionView(newVar) {
      if (newVar == 6) {
        gtag('event', 'recognizerchangetext', { 'event_category': undefined, 'event_label': this.s1qnum });
      }
    }
  }
};
</script>
