<template>
    <div>
        <v-dialog v-model="isOpen" max-width="400">
            <v-card v-if="viewStatus == 0" class="pa-0 ma-0">
                <v-card-title class="dialogReset">
                </v-card-title>
                <v-card-text class="dialogReset">
                    <v-row v-if="countHintPoint > 0" class="dialogReset">
                        <v-col cols="12" class="pt-7 text-center">
                            <div class="checkBg"
                                :style="{ 'background-image': 'url(/images/hint_zukanbo_check.jpg)', 'height': '313px' }">
                                <v-row class="text-center" style="padding-top:180px">
                                    <v-col cols="12" class="">
                                        <v-btn width="50%" class="btn_orange" @click="useLife">
                                            ヒント教えて！
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" class="">
                                        <v-btn width="50%" class="btn_blue" @click="isOpen = false">
                                            もう少し考える
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                        <v-col cols="12" class="pt-1 pb-0 mb-1 lifeText text-center">
                            あと{{ countHintPoint }}回
                        </v-col>
                        <v-col cols="12" class="pt-1 text-center">
                            <div class="lifeIcons">
                                <img src="/images/hint_hart_on.png" v-if="countHintPoint == 3" />
                                <img src="/images/hint_hart_off.png" v-else />
                                <img src="/images/hint_hart_on.png" v-if="countHintPoint >= 2" />
                                <img src="/images/hint_hart_off.png" v-else />
                                <img src="/images/hint_hart_on.png" v-if="countHintPoint >= 1" />
                                <img src="/images/hint_hart_off.png" v-else />
                            </div>
                        </v-col>
                    </v-row>
                    <v-row v-else class="dialogReset">
                        <v-col cols="12" class="pa-0 text-center">
                            <div class="tryBg"
                                :style="{ 'background-image': 'url(/images/hint_zukanbo_try.jpg)', 'height': '520px' }">
                                <v-row class="text-center">
                                    <v-col cols="12" class="pt-10 text-center">
                                        <div class="lifeIcons">
                                            <img src="/images/hint_hart_off.png" />
                                            <img src="/images/hint_hart_off.png" />
                                            <img src="/images/hint_hart_off.png" />
                                        </div>
                                    </v-col>
                                    <v-col cols="12" class="pt-0 text-center lifeText">
                                        ロッカー出口にある<br />
                                        QRで回復して<br />
                                        またヒントを聞き出そう！
                                    </v-col>
                                    <v-col cols="6" style="margin-top:270px" class="pl-5 pr-0 text-center">
                                        <v-btn width="80%" class="btn_orange" @click="viewStatus = 2">移動してQRを読む
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="6" style="margin-top:270px" class="pl-0 pr-5 text-center">
                                        <v-btn width="80%" class="btn_blue" @click="isOpen = false">閉じる</v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card v-else-if="viewStatus == 1">
                <v-card-title class="dialogText">
                    <v-img src="/images/yune_title_hint.png" />
                </v-card-title>
                <v-card-text class="dialogText">
                    <v-row>
                        <v-col cols="12" class="pt-5 pb-3">
                            <div v-html="message"></div>
                        </v-col>
                        <v-col cols="12" class="text-center">
                            <v-btn class="btn_blue" @click="isOpen = false">閉じる</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card v-else-if="viewStatus == 2">
                <v-card-title class="dialogText">QRコードを読み取ってください</v-card-title>
                <v-card-text class="dialogText">
                    <div class="text-center">
                        <canvas ref="capCamera" width="240px" height="240px"></canvas>
                    </div>
                    <v-alert v-model="qrTypeError" type="error">
                        <span>異なったQRコードが読まれました</span>
                    </v-alert>
                    <div class="text-center">
                        <v-btn class="btn_blue" @click="viewStatus = 0">閉じる</v-btn>
                    </div>
                    <!-- <div class="text-center">
                        <v-btn @click="recoverHintPoint">TEST：回復させる</v-btn>
                    </div> -->
                </v-card-text>
            </v-card>

            <v-card v-else-if="viewStatus == 3">
                <v-card-title class="dialogReset">
                </v-card-title>
                <v-card-text class="dialogReset">
                    <v-row class="dialogReset">
                        <v-col cols="12" class="pa-0 text-center">
                            <div class="recoverBg"
                                :style="{ 'background-image': 'url(/images/hint_zukanbo_recover.jpg)', 'height': '480px' }">
                                <v-row class="text-center">
                                    <v-col cols="12" class="pt-10 text-center lifeText">
                                        ライフが全回復した！
                                    </v-col>
                                    <v-col cols="12" class="pt-0 text-center">
                                        <div class="lifeIcons">
                                            <img src="/images/hint_hart_on.png" />
                                            <img src="/images/hint_hart_on.png" />
                                            <img src="/images/hint_hart_on.png" />
                                        </div>
                                    </v-col>
                                    <v-col cols="12" style="margin-top:260px" class="text-center">
                                        <v-btn class="btn_orange" @click="viewStatus = 0">謎解きを再開する
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<style scoped>
.checkBg {
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
}

.tryBg {
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
}

.recoverBg {
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
}

.lifeText {
    font-size: 20px;
    font-weight: bold;
}

.lifeIcons>img {
    padding: 0 3px;
}

.dialogReset {
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;

}
</style>

<script>
export default {
    data: () => ({
        viewStatus: 0,
        isOpen: false,
        qrTypeError: false
    }),
    props: {
        message: String
    },
    computed: {
        nowMissionSection() {
            return this.$store.getters.nowMissionSection;
        },
        nowMissionChapter() {
            return this.$store.getters.nowMissionChapter;
        },
        nowMission() {
            return this.$store.getters.nowMissionChapter + "-" + this.$store.getters.nowMissionSection;
        },
        countHintPoint() {
            return this.$store.getters.countHintPoint;
        },
        isUseHitPoint() {
            const hintPoints = this.$store.getters.hintPoints;
            let isUseKey = false;
            if (hintPoints) {
                isUseKey = hintPoints.indexOf(this.getHintKey()) != -1;
            }
            // console.log(hintPoints, this.getHintKey(), isUseKey);
            return isUseKey;
        },
    },
    created: function () {
        if (this.message && this.message != "") {
            this.viewStatus = 0;
            this.isOpen = true;
        }
    },
    methods: {
        getHintKey() {
            let hintKey = this.nowMissionChapter + "-" + this.nowMissionSection;
            if (hintKey == "2-2") hintKey = "2-1";
            return hintKey;
        },
        useLife() {
            this.viewStatus = 1;
            this.$store.commit("useHintPoint", this.getHintKey());
            gtag('event', 'helpview', { 'event_category': this.nowMission, 'event_label': this.$store.getters.hintPoints.join(",") });
        },
        viewQrReader(count) {
            if (!count) count = 1;
            if (this.$refs.capCamera) {
                this.$stamp.qrCamera(this.$refs.capCamera, (event) => {
                    // 指定のQRを探す
                    // console.log("QE", event);

                    // console.log("readQrs", this.$store.getters.qrcode);
                    // console.log("readQrs1", event.data, this.$store.getters.qrcode[event.data]);
                    if (this.$store.getters.qrcode[event.data]) {
                        const readQrs = this.$store.getters.qrcode[event.data];
                        let okQr = false;
                        for (let readQr of readQrs) {
                            if (readQr.type == "recovery") {
                                okQr = true;
                            }
                        }
                        if (okQr) {
                            this.recoverHintPoint();
                        } else {
                            this.qrTypeError = true;
                        }
                    } else {
                        this.qrTypeError = true;
                    }

                })
            } else if (count < 5) {
                setTimeout(() => {
                    this.viewQrReader(count + 1)
                }, 300);
            }
        },
        recoverHintPoint() {
            this.$stamp.stopCamera();
            this.qrTypeError = false;
            this.$store.commit("recoverHintPoint", true);
            this.viewStatus = 3;
            gtag('event', 'helprecover', { 'event_category': this.nowMission, 'event_label': this.$store.getters.hintPoints.join(",") });
        }
    },
    watch: {
        message(newVar) {
            if (newVar && newVar != '') {
                this.viewStatus = this.isUseHitPoint ? 1 : 0;
                this.isOpen = true;
            } else {
                this.isOpen = false;
            }
        },
        isOpen(newVal) {
            if (!newVal) {
                this.$emit('close');
            }
        },
        viewStatus(newVal) {
            if (newVal == 2) {
                this.viewQrReader();
            } else {
                this.$stamp.stopCamera();
            }

        }
    }
};
</script>

<style>
</style>