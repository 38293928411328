<template>
  <v-app>
    <header>
      <v-row class="btn-container" v-if="nowMissionChapter != 0">
        <v-btn fab @click="isOpenMenu = true" v-if="isOpenMenu == false" color="rgba(255, 255, 255, 0.5)">
          <v-icon color="#000000">mdi-menu</v-icon>
        </v-btn>
      </v-row>

      <v-navigation-drawer v-model="isOpenMenu" fixed temporary :right="true">
        <v-list>

          <v-list-item to="/map">
            <v-list-item-icon>
              <v-icon color="pink darken-4">mdi-map</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>エリアマップ</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/book">
            <v-list-item-icon>
              <v-icon color="pink darken-4">mdi-book-open-blank-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>おばけずかん</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list>
        <v-divider></v-divider>
        <v-list v-if="nowMissionChapter >= 1">

          <v-list-group v-model="isOpenMission" color="indigo darken-1" prepend-icon="mdi-ghost" no-action>
            <template v-slot:activator>
              <v-list-item-title><span class="black--text">MISSION</span></v-list-item-title>
            </template>

            <v-list-item to="/mission1" v-if="nowMissionChapter >= 1">
              <v-list-item-content>
                <v-list-item-title
                  :class='[nowMissionChapter == 1 ? "font-weight-bold indigo--text text--darken-1" : ""]'>－ MISSION 1
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/mission2" v-if="nowMissionChapter >= 2">
              <v-list-item-content>
                <v-list-item-title
                  :class='[nowMissionChapter == 2 ? "font-weight-bold indigo--text text--darken-1" : ""]'>－ MISSION 2
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/mission3" v-if="nowMissionChapter >= 3">
              <v-list-item-content>
                <v-list-item-title
                  :class='[nowMissionChapter == 3 ? "font-weight-bold indigo--text text--darken-1" : ""]'>－ MISSION 3
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/mission4" v-if="nowMissionChapter > 4 || (nowMissionChapter == 4)">
              <v-list-item-content>
                <v-list-item-title
                  :class='[nowMissionChapter == 4 ? "font-weight-bold indigo--text text--darken-1" : ""]'>－ MISSION 4
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/mission5" v-if="nowMissionChapter > 5 || (nowMissionChapter == 5)">
              <v-list-item-content>
                <v-list-item-title
                  :class='[nowMissionChapter == 5 ? "font-weight-bold indigo--text text--darken-1" : ""]'>－ MISSION 5
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/mission6"
              v-if="nowMissionChapter > 6 || (nowMissionChapter == 6 && nowMissionSection != 0)">
              <v-list-item-content>
                <v-list-item-title
                  :class='[nowMissionChapter == 6 ? "font-weight-bold indigo--text text--darken-1" : ""]'>－ 後日談
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

        </v-list>
        <v-divider></v-divider>
        <v-list>

          <v-list-item to="/present" v-if="nowMissionChapter >= 6">
            <v-list-item-icon>
              <v-icon color="#3b5660">mdi-gift</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>プレゼント</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/ofuro">
            <v-list-item-icon>
              <v-icon color="#3b5660">mdi-hot-tub</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <div class="bgbargray" style="font-size:0.8em;color:#aaa;">撮影スポット</div>
                コラボ風呂
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/top">
            <v-list-item-icon>
              <v-icon color="#3b5660">mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>TOP</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list>
        <div v-if="$store.getters.isMaintenance">
          <v-divider>
          </v-divider>
          <v-list>
            <v-list-item to="/maintenance">
              <v-list-item-icon>
                <v-icon color="red">mdi-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>メンテナンス</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-navigation-drawer>
    </header>
    <v-main>
      <router-view />
    </v-main>
    <v-footer padless color="transparent">
      <v-col class="text-center pb-5 footer-copy" cols="12" style="font-size:12px;">
        &copy;2022「GHOSTBOOK おばけずかん」製作委員会
      </v-col>
    </v-footer>
    <!-- mission6への導線がらみ -->
    <div class="footerMailBtn"
      v-if="nowMissionChapter == 6 && nowPath != '/mission6' && (nowPath != '/map' || nowMissionSection == 0)"
      @click="viewDialogInfo = true">
      <img src="/images/mission6_mail.png" class="flashicon" width="80%" />
    </div>
    <v-dialog v-model="viewDialogInfo" max-width="400">
      <v-card>
        <v-card-text class="dialogText">
          <v-row class="pt-10">
            <v-col cols=" 12">
              ん？お化け図鑑が光っているぞ…？<br />
              中にはジズリたちからの手紙。一体何て書いてあるんだ？<br />
              どうやら<span class="text-att">コラボ風呂</span>に謎を解く鍵がありそうだ！
            </v-col>
            <v-col cols="12" class="pa-0 text-center ">
              <div :style="{ 'background-image': 'url(/images/map_zukanbo_info_bg.jpg)', 'height': '230px' }"
                class="text-center bgtop">
                <v-btn width="80%" class="btn_orange" style="margin-top:175px" @click="goMission6">
                  進む</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- mission6への導線がらみ -->

  </v-app>
</template>
<style>
</style>
<style scoped>
.bgtop {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
}

.flashicon {
  animation: flash 1.5s infinite;
}

@keyframes flash {

  0%,
  100% {
    filter: drop-shadow(0 0 15px rgba(255, 255, 172, 0.8));
  }

  7%,
  93% {
    filter: drop-shadow(0 0 12px rgba(255, 255, 172, 0.75));
  }

  16%,
  84% {
    filter: drop-shadow(0 0 10px rgba(255, 255, 172, 0.67));
  }

  50% {
    filter: drop-shadow(0 0 10px rgba(255, 255, 172, 0)) opacity(80%);
    ;
  }
}
</style>
<script>
import axios from "axios";

export default {
  name: "App",

  data: () => ({
    isOpenMenu: false,
    isOpenMission: true,
    viewDialogInfo: false
    // nowMissionChapter: 9,
  }),
  created: async function () {
    this.$store.subscribe((mutation, state) => {
      // console.log("save local subscribe", mutation.type);
      if (!state.auth.lineUserHashKey && !state.auth.localUserHashKey) {
        // console.log("☆NO SET USER", state.auth);
        return;
      }

      if (["init", "setTos", "checkDevice", "setUserIds", "setMaintenance"].indexOf(mutation.type) == -1) {
        const saveData = {
          lineUserHashKey: state.auth.lineUserHashKey,
          nowMissionChapter: state.auth.nowMissionChapter,
          nowMissionSection: state.auth.nowMissionSection,
          hintPoints: state.auth.hintPoints,
          checkTos: state.auth.checkTos,
          recoverHintPointNum: state.auth.recoverHintPointNum,
          localUserHashKey: state.auth.localUserHashKey
        }

        // console.log("★★SaveServeData ", saveData);
        axios.post(
          'https://9o49cj3vk3.execute-api.ap-northeast-1.amazonaws.com/prod/user',
          saveData)
          .then((res) => {
            // console.log("☆ServerRes", res);
          })
          .catch((error) => {
            console.error("☆ServerERROR", error);
          });
        localStorage.setItem(this.$store.getters.localStorageKey, JSON.stringify(saveData));

        gtag('event', 'progress', { 'event_category': this.nowMission, 'event_label': undefined });
      }
    });

    // 他のページが開いてたら強制移動
    if (!this.$store.getters.isLoad && this.nowPath != "/") {
      this.$router.push('/');
    }

    // Azure音声認識をレイジーロード
    if (!window.SpeechSDK) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = "https://aka.ms/csspeech/jsbrowserpackageraw";
      const firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode.insertBefore(script, firstScript);
    }

  },
  methods: {
    goMission6() {
      this.viewDialogInfo = false;
      if (this.nowMissionSection == 0) {
        this.$store.commit("setMissionSection", 1);
      }
      this.$router.push('/mission6');
    }
  },
  computed: {
    nowMissionChapter() {
      return this.$store.getters.nowMissionChapter;
    },
    nowMissionSection() {
      return this.$store.getters.nowMissionSection;
    },
    nowMission() {
      return this.$store.getters.nowMissionChapter + "-" + this.$store.getters.nowMissionSection;
    },
    nowPath() {
      return this.$route.path;
    }
  },
  watch: {
    nowPath(newVar) {
      let appDom = document.getElementById("app");
      switch (newVar) {
        case "/mission6":
          appDom.classList.add("goziBg", "imagepage");
          appDom.classList.remove("blackBg", "topBg", "presentBg");
          break;
        case "/present":
          appDom.classList.add("presentBg");
          appDom.classList.remove("topBg", "blackBg", "goziBg", "imagepage");
          break;
        case "/top":
          appDom.classList.add("topBg");
          appDom.classList.remove("blackBg", "goziBg", "imagepage", "presentBg");
          break;
        case "/book":
        case "/map":
          appDom.classList.add("imagepage", "blackBg");
          appDom.classList.remove("goziBg", "topBg", "presentBg");
          break;
        case "/ofuro":
          appDom.classList.add("imagepage");
          appDom.classList.remove("blackBg", "goziBg", "topBg", "presentBg");
          break;
        default:
          appDom.classList.remove("blackBg", "goziBg", "topBg", "imagepage", "presentBg");
          break;
      }
    }
  }
};
</script>
