<template>
    <v-dialog v-model="isOpen" persistent max-width="368px" class="movieDialog">
        <v-card style="overflow: hidden;">
            <v-card-text class="pa-0 ma-0">
                <v-row class="pa-0 ma-0" style="background-color:gray;">
                    <v-col cols="12" class="pa-0 ma-0 text-center">
                        <div ref="movieArea" style="margin:0 !important;padding:0 !important;">
                        </div>
                    </v-col>
                    <v-col cols="12" class="pa-0 text-right pr-3">
                        <v-btn text @click="isOpen = false;" class="pa-0 ma-0 skipBtn">&gt;&gt; スキップ</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    data: () => ({
        isOpen: true
    }),
    props: {
        movie: HTMLVideoElement,
    },
    created: function () {
        this.movie.setAttribute("muted", "");
        this.movie.setAttribute("playsinline", "");
        this.movie.setAttribute("width", "100%");
        this.movie.addEventListener("ended", () => {
            this.isOpen = false;
            gtag('event', 'movie', { 'event_category': this.nowMission, 'event_label': 'end' });
        })
        this.playVideo();
    },
    destroyed: function () {
        this.movie.pause();
    },
    methods: {
        playVideo(count) {
            if (!count) count = 0;
            if (this.$refs.movieArea) {
                // console.log(this.$refs.movieArea, this.movie);
                this.$refs.movieArea.innerHTML = "";
                this.$refs.movieArea.appendChild(this.movie);
                this.movie.currentTime = 0;
                this.movie.play();
            } else if (count < 10) {
                setTimeout(() => {
                    this.playVideo(count + 1);
                }, 100)
            }
        },
    },
    computed: {
        nowMissionChapter() {
            return this.$store.getters.nowMissionChapter;
        },
        nowMissionSection() {
            return this.$store.getters.nowMissionSection;
        },
        nowMission() {
            return this.$store.getters.nowMissionChapter + "-" + this.$store.getters.nowMissionSection;
        }
    },
    watch: {
        isOpen(newVal) {
            if (!newVal) {
                try {
                    this.movie.pause();
                    this.movie.currentTime = 0;
                } catch (error) {
                    console.log(error);
                }
                this.$emit('close');
            }
        }
    }
}
</script>