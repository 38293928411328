<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center">
        <img src="/images/yune_title_mission2.png" width="70%" />
      </v-col>
      <v-col cols="12" class="text-left pl-10 pr-10">
        おばけずかんの情報によって導かれる。そこには赤い水が沸いた<span class="text-att">ワイン風呂</span>がある。<br />
        お風呂の近くで何かが飛んでいる…？ あれは…。白い、ぬ…の…？<br />
        ここには赤い塗料(ワイン風呂)があるぞ。これをあの布に目掛けてかけるのか？<br />
        ん？？何か文字が浮かんできたぞ？
      </v-col>
    </v-row>

    <div class="nazoM">
      <div style="width:100%">
        <v-row class="text-center">
          <v-col cols="12" class="pb-5" style="font-weight: bold;">
            一反木綿をワインで汚し<br />
            関連する場所へ行き、<br />
            浮かんだ言葉を1つ答えよ
          </v-col>
        </v-row>
        <v-row class="text-center" v-if="nowMissionChapter == 2 && nowMissionSection < 3">
          <v-col cols="6" class="pt-0">
            <v-btn width="100%" class="btn_orange" @click="viewDialogS1 = true">QRを読む</v-btn>
          </v-col>
          <v-col cols="6" class="pt-0">
            <v-btn width="100%"
              :class="$store.getters.useHintList.indexOf(nowMission) == -1 ? 'btn_blue' : 'btn_orange'" @click="s1Hint">
              ヒントを聞く</v-btn>
          </v-col>
        </v-row>
        <v-row class="text-center" v-else>
          <v-col class="answerTitle">
            答え
          </v-col>
          <v-col cols="9" class="answerArea">
            くろ・まめ・<br />ミルク・かん
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row v-if="nowMissionChapter > 2 || (nowMissionChapter == 2 && nowMissionSection >= 3)">
      <v-col cols="12" class="text-left pl-10 pr-10">
        あ、待てーーーーー！！一反木綿が<span class="text-att">ワイン風呂</span>から逃げていき、<span
          class="text-att">コーヒー風呂</span>にたどり着いた。身体を汚されたせいか弱っている…？あともう少しで封印できそうだ。弱った一反木綿が、訳わからない物を出してきた。これはなんだろう？
      </v-col>
      <v-col cols="12" class="text-center">
        <div class="nazoL">
          <div style="width:100%">
            <v-row class="text-center">
              <v-col cols="12" class="pa-0 pb-0" style="font-weight: bold;">
                <img src="/images/mission2_8o9.png" width="42px" />は何？<br />
                ひらがな<span style="font-size:0.6em">もしくは</span>カタカナで答えよ
              </v-col>
              <v-col cols="12" class="pb-0 pt-2 pl-9 pr-9 text-center">
                <v-img src="/images/mission2_8o9_hint.png"></v-img>
              </v-col>
            </v-row>
            <v-row class="text-center" v-if="nowMissionChapter == 2 && nowMissionSection < 4">
              <v-col cols="6" class="pt-3">
                <v-btn width="100%" class="btn_orange" @click="viewDialogS3 = true">答える</v-btn>
              </v-col>
              <v-col cols="6" class="pt-3">
                <v-btn width="100%"
                  :class="$store.getters.useHintList.indexOf(nowMission) == -1 ? 'btn_blue' : 'btn_orange'"
                  @click="s3Hint">ヒントを聞く</v-btn>
              </v-col>
            </v-row>
            <v-row class="text-center pt-5" v-else>
              <v-col class="answerTitle">
                答え
              </v-col>
              <v-col cols="9" class="answerArea">
                ねっき
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="nowMissionChapter > 2 || (nowMissionChapter == 2 && nowMissionSection >= 4)">
      <v-col cols="12" class="text-left pl-10 pr-10">
        これが一反木綿の弱点か！よし！<br />
        熱いお風呂のお湯をかけてやろう！
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn class="btn_orange" @click="viewDialogS4 = true">ムービーを見る</v-btn>
      </v-col>
    </v-row>

    <v-row v-if="nowMissionChapter > 2 || (nowMissionChapter == 2 && nowMissionSection >= 5)">
      <v-col cols="12">
        <div class="nazoS">
          <div style="width:100%">
            <v-row class="text-center pt-0 pb-0 mb-0">
              <v-col cols="12" style="font-weight: bold;">
                一反木綿の特技は？
              </v-col>
            </v-row>
            <v-row class="text-center" v-if="nowMissionChapter == 2 && nowMissionSection <= 5">
              <v-col cols="6" class="pt-0">
                <v-btn width="100%" class="btn_orange" @click="viewDialogS5 = true">答える</v-btn>
              </v-col>
              <v-col cols="6" class="pt-0">
                <v-btn width="100%" @click="viewDialogS4 = true" class="btn_blue">もう一度見る</v-btn>
              </v-col>
            </v-row>
            <v-row class="text-center" v-else>
              <v-col class="answerTitle">
                答え
              </v-col>
              <v-col cols="9" class="answerArea">
                まきつく
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="text-center pa-10">
      <v-btn width="80%" class="btn_orange" @click="$router.push('/map');">エリアマップへ</v-btn>
    </div>


    <!---------------------->

    <QrWindow v-if="viewDialogS1" @close="viewDialogS1 = false" @correct="s1AnswerOk"></QrWindow>

    <!---------------------->
    <InputWindow v-if="viewDialogS2" :answerTexts="['くろ', 'まめ', 'ミルク', 'かん']"
      :hintBtnColor="$store.getters.useHintList.indexOf(nowMission) == -1 ? 'blue' : 'orange'"
      @close="viewDialogS2 = false" @hint="s1Hint" @correct="s2AnswerOk">
      <template v-slot:question>
        ワイン風呂で浮かんだ言葉をどれか1つ答えよ
      </template>
      <template v-slot:hint>
        ヒントを聞く
      </template>
    </InputWindow>

    <!---------------------->

    <InputWindow v-if="viewDialogS3" :answerTexts="['ねっき', 'ネッキ']"
      :hintBtnColor="$store.getters.useHintList.indexOf(nowMission) == -1 ? 'blue' : 'orange'"
      @close="viewDialogS3 = false" @hint="s3Hint" @correct="s3AnswerOk">
      <template v-slot:question>
        暗号の答えは？
      </template>
      <template v-slot:hint>
        ヒントを聞く
      </template>
    </InputWindow>

    <!---------------------->

    <MovieWindow v-if="viewDialogS4" @close="viewDialogS4 = false" :movie="characteristicVideo"></MovieWindow>

    <!---------------------->

    <InputWindow v-if="viewDialogS5" :answerTexts="['まきつく']" @close="viewDialogS5 = false" @hint="viewDialogS4 = true"
      @correct="s5AnswerOk">
      <template v-slot:question>
        一反木綿の特技は？
      </template>
      <template v-slot:hint>
        もう一度見る
      </template>
    </InputWindow>

    <!---------------------->

    <HelpWindow :message="hintMessage" @close="hintMessage = null"></HelpWindow>

    <!---------------------->
    <!-- <div v-if="$store.getters.isMaintenance">
      <v-btn @click='$store.commit("setNextMission", 2); $store.commit("setMissionSection", 1)'>1</v-btn>
      <v-btn @click='$store.commit("setNextMission", 2); $store.commit("setMissionSection", 3)'>3</v-btn>
      <v-btn @click='$store.commit("setNextMission", 2); $store.commit("setMissionSection", 4)'>4</v-btn>
      <v-btn @click='$store.commit("setNextMission", 2); $store.commit("setMissionSection", 5)'>5</v-btn>
      <v-btn @click='missionClear()'>END</v-btn>
    </div> -->



  </v-container>
</template>
<style scoped>
</style>
<script>
import HelpWindow from "./components/HelpWindow";
import InputWindow from "./components/InputWindow";
import QrWindow from "./components/QrWindow";
import MovieWindow from "./components/MovieWindow";

export default {
  components: {
    HelpWindow,
    InputWindow,
    QrWindow,
    MovieWindow
  },
  data: () => ({
    viewDialogS1: false,
    viewDialogS2: false,
    viewDialogS3: false,
    viewDialogS4: false,
    viewDialogS5: false,
    characteristicVideo: null,
    hintMessage: null
  }),
  created: function () {
    if (this.nowMissionChapter < 2) {
      this.$router.push('/');
    } else if (this.nowMissionChapter == 2 && this.nowMissionSection == 0) {
      this.$router.push('/map');
    }

    this.characteristicVideo = document.createElement("video");
    this.characteristicVideo.src = "movies/mission2_1.mp4";

  },
  destroyed: function () {

  },
  methods: {
    s1AnswerOk() {
      this.viewDialogS1 = false;
      this.viewDialogS2 = true;
      // section2はヘルプの都合でナシにする
      // this.$store.commit("setMissionSection", 2);
    },

    s2AnswerOk() {
      this.viewDialogS2 = false;
      this.$store.commit("setMissionSection", 3);
    },
    s3AnswerOk() {
      this.viewDialogS3 = false;
      this.$store.commit("setMissionSection", 4);
    },
    s5AnswerOk() {
      this.viewDialogS5 = false;
      this.missionClear();
    },

    missionClear() {
      this.$store.commit("setNextMission", 3);
      this.$router.push({ name: 'book', params: { 'seal': true } });
    },
    s1Hint() {
      this.hintMessage = "布のお化けにワイン風呂のワインをかけてみよう！するとなにやら文字が浮かんでくるよ！その言葉に共通してつく言葉はなにかな？<br/>大人が好きなあの飲み物だよ。";
    },
    s3Hint() {
      this.hintMessage = "コーヒー豆とポットのイラストに何か見覚えがないかな？コーヒー風呂をよーく見てみよう！見つけたら、そこに書いてある文字と見比べて、記号の場所を入れてみよう。";
    }
  },
  computed: {
    nowMissionChapter() {
      return this.$store.getters.nowMissionChapter;
    },
    nowMissionSection() {
      return this.$store.getters.nowMissionSection;
    },
    nowMission() {
      return this.$store.getters.nowMissionChapter + "-" + this.$store.getters.nowMissionSection;
    }
  },
  watch: {
    viewDialogS4(newVal) {
      if (newVal) {
        this.viewDialogS5 = false;
      } else {
        if (this.nowMissionChapter == 2 && (this.nowMissionSection == 4 || this.nowMissionSection == 5)) {
          this.$store.commit("setMissionSection", 5);
          this.viewDialogS5 = true;
        }
      }
    }
  }
};
</script>
