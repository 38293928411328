<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <img src="/images/yune_title_mission3.png" width="70%" />
      </v-col>
      <v-col cols="12" class="text-left pl-10 pr-10">
        おばけずかんの情報によって導かれる。そこは大きな岩がそびえ立つロデオマウンテン内部の<span class="text-att">洞窟風呂</span>。<br />
        ここは今までと違って暗く狭い…。そして道は奥へ続いている。 奥に進むにつれてあちこちから視線を感じる気が…？<br />
        いや、これは気のせいではなくあちこちに目があるんだ！！！ここにいるおばけをなんとしてでも封印しなくては！！
      </v-col>
    </v-row>

    <div class="nazoML">
      <div style="width:100%">
        <v-row class="text-center">
          <v-col cols="12" class="pa-0" style="font-weight: bold;">
            大きな目の弱点は？
          </v-col>
          <v-col cols="12" class="pb-3">
            <v-img src="/images/mission3_eye.gif"></v-img>
          </v-col>
        </v-row>
        <v-row class="text-center" v-if="nowMissionChapter == 3 && nowMissionSection <= 1">
          <v-col cols="6" class="pt-0">
            <v-btn width="100%" class="btn_orange" @click="viewDialogS1 = true">答える</v-btn>
          </v-col>
          <v-col cols="6" class="pt-0">
            <v-btn width="100%"
              :class="$store.getters.useHintList.indexOf(nowMission) == -1 ? 'btn_blue' : 'btn_orange'" @click="s1Hint">
              ヒントを聞く</v-btn>
          </v-col>
        </v-row>
        <v-row class="text-center" v-else>
          <v-col class="answerTitle">
            答え
          </v-col>
          <v-col cols="9" class="answerArea">
            たまねぎ
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row v-if="nowMissionChapter > 3 || (nowMissionChapter == 3 && nowMissionSection >= 2)">
      <v-col cols="12" class="text-left pl-10 pr-10">
        たまねぎの汁をかけると、目の持ち主・百目が現れた…！
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn class="btn_orange" @click="viewDialogS2 = true">ムービーを見る</v-btn>
      </v-col>
    </v-row>

    <v-row v-if="nowMissionChapter > 3 || (nowMissionChapter == 3 && nowMissionSection >= 3)">
      <v-col cols="12">
        <div class="nazoS">
          <div style="width:100%">
            <v-row class="text-center pt-0 pb-0 mb-0">
              <v-col cols="12" style="font-weight: bold;">
                百目の特技は？
              </v-col>
            </v-row>
            <v-row class="text-center" v-if="nowMissionChapter == 3 && nowMissionSection <= 3">
              <v-col cols="6" class="pt-0">
                <v-btn width="100%" class="btn_orange" @click="viewDialogS3 = true">答える</v-btn>
              </v-col>
              <v-col cols="6" class="pt-0">
                <v-btn width="100%" @click="viewDialogS2 = true" class="btn_blue">もう一度見る</v-btn>
              </v-col>
            </v-row>
            <v-row class="text-center" v-else>
              <v-col class="answerTitle">
                答え
              </v-col>
              <v-col cols="9" class="answerArea">
                しゃげき
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="text-center pa-10">
      <v-btn width="80%" class="btn_orange" @click="$router.push('/map');">エリアマップへ</v-btn>
    </div>

    <!---------------------->

    <InputWindow v-if="viewDialogS1" :answerTexts="['たまねぎ']" @close="viewDialogS1 = false"
      :hintBtnColor="$store.getters.useHintList.indexOf(nowMission) == -1 ? 'blue' : 'orange'" @hint="s1Hint"
      @correct="s1AnswerOk">
      <template v-slot:question>
        大きな目の弱点は？
      </template>
      <template v-slot:hint>
        ヒントを聞く
      </template>
    </InputWindow>

    <!---------------------->

    <MovieWindow v-if="viewDialogS2" @close="viewDialogS2 = false" :movie="characteristicVideo"></MovieWindow>

    <!---------------------->

    <InputWindow v-if="viewDialogS3" :answerTexts="['しゃげき']" @close="viewDialogS3 = false" @hint="viewDialogS2 = true"
      @correct="s3AnswerOk">
      <template v-slot:question>
        百目の特技は？
      </template>
      <template v-slot:hint>
        もう一度見る
      </template>
    </InputWindow>
    <!---------------------->

    <HelpWindow :message="hintMessage" @close="hintMessage = null"></HelpWindow>

    <!---------------------->
    <!-- <div v-if="$store.getters.isMaintenance">
      <v-btn @click='$store.commit("setNextMission", 3); $store.commit("setMissionSection", 1)'>1
      </v-btn>
      <v-btn @click='$store.commit("setNextMission", 3); $store.commit("setMissionSection", 2)'>2
      </v-btn>
      <v-btn @click='$store.commit("setNextMission", 3); $store.commit("setMissionSection", 3)'>3
      </v-btn>
      <v-btn @click='missionClear()'>END</v-btn>
    </div> -->

  </v-container>
</template>
<style scoped>
</style>
<script>
import HelpWindow from "./components/HelpWindow";
import InputWindow from "./components/InputWindow";
import MovieWindow from "./components/MovieWindow";

export default {
  components: {
    HelpWindow,
    InputWindow,
    MovieWindow
  },
  data: () => ({
    // viewDialogHint: false,
    viewDialogS1: false,
    viewDialogS2: false,
    viewDialogS3: false,
    characteristicVideo: null,
    hintMessage: null
  }),
  created: function () {
    if (this.nowMissionChapter < 3) {
      this.$router.push('/');
    } else if (this.nowMissionChapter == 3 && this.nowMissionSection == 0) {
      this.$router.push('/map');
    }

    this.characteristicVideo = document.createElement("video");
    this.characteristicVideo.src = "movies/mission3_1.mp4";

  },
  destroyed: function () {

  },
  methods: {

    s1AnswerOk() {
      this.viewDialogS1 = false;
      this.$store.commit("setMissionSection", 2);
    },
    s3AnswerOk() {
      this.viewDialogS3 = false;
      this.missionClear();
    },

    missionClear() {
      this.$store.commit("setNextMission", 4);
      this.$router.push({ name: 'book', params: { 'seal': true } });
    },

    s1Hint() {
      this.hintMessage = "洞窟の目がもし動いていたら、なにを見ているかな？<br />動いている目の方向をよく見てみて！<br />目が真ん中に戻ると１文字目の合図だよ。"
    }
  },
  computed: {
    nowMissionChapter() {
      return this.$store.getters.nowMissionChapter;
    },
    nowMissionSection() {
      return this.$store.getters.nowMissionSection;
    },
    nowMission() {
      return this.$store.getters.nowMissionChapter + "-" + this.$store.getters.nowMissionSection;
    }
  },
  watch: {
    viewDialogS2(newVal) {
      if (newVal) {
        this.viewDialogS3 = false;
      } else {
        if (this.nowMissionChapter == 3 && (this.nowMissionSection == 2 || this.nowMissionSection == 3)) {
          this.$store.commit("setMissionSection", 3);
          this.viewDialogS3 = true;
        }
      }
    }
  }
};
</script>
