<template>
    <v-dialog v-model="isOpen" max-width="400" class="correctBase" transition="fade-transition">
        <v-card>
            <v-card-title>
                <v-row class="text-center" style="font-weight: bold;">
                    <v-col cols="12" v-if="!isAnswerError">
                        <slot name="question"></slot>
                    </v-col>
                    <v-col cols="12" v-else>
                        残念！答えが<br />
                        違っているようだ
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text class="dialogText">
                <v-row class="text-center" v-if="!isAnswerError">
                    <v-col cols="12" class="pa-0 pt-3">
                        <v-col cols="12" class="pa-0">
                            <v-text-field v-model="inputText" placeholder="ここに文字を入力" outlined>
                            </v-text-field>
                        </v-col>
                    </v-col>
                    <v-col cols="6" class="pt-0">
                        <v-btn width="100%" @click="answerCheck" class="btn_orange">答える</v-btn>
                    </v-col>
                    <v-col cols="6" class="pt-0">
                        <v-btn width="100%" @click="hint" :class="hintBtnClass">
                            <slot name="hint"></slot>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="text-center" v-else>
                    <v-col cols="12" class="pt-5">
                        <v-btn width="80%" @click="isAnswerError = false" class="btn_orange">もう一度答える</v-btn>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                        <v-btn width="80%" @click="hint" :class="hintBtnClass">
                            <slot name="hint"></slot>
                        </v-btn>
                    </v-col>
                </v-row>
                <div class="correct" v-if="correctAnime">
                    <v-img src="/images/correct.svg"></v-img>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<style scoped>
.correctBase {
    position: relative;
}

.correct {
    position: absolute;
    animation-name: correctfade;
    animation-duration: 1400ms;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    opacity: 0;
}

.correct>* {
    margin: 25px auto;
    width: 33%;
}

@keyframes correctfade {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}
</style>
<script>
export default {
    data: () => ({
        isOpen: true,
        isAnswerError: false,
        inputText: "",
        correctAnime: false,
        hintBtnClass: "btn_blue",
        eventTimer: null
    }),
    props: {
        answerTexts: Array,
        hintBtnColor: String
    },
    created: function () {
        // console.log("start btn color", this.hintBtnColor)
        if (this.hintBtnColor == "orange") {
            this.hintBtnClass = "btn_orange";
        }
        clearTimeout(this.eventTimer);
    },
    methods: {
        answerCheck() {
            clearTimeout(this.eventTimer);
            if (this.answerTexts.indexOf(this.inputText) >= 0) {
                this.correctAnime = true;
                this.eventTimer = setTimeout(() => {
                    this.$emit('correct');
                }, 1300);
            } else {
                gtag('event', 'miss', { 'event_category': this.nowMission, 'event_label': this.inputText });
                this.isAnswerError = true;
            }
        },
        hint() {
            this.$emit('hint');
        }
    },
    computed: {
        nowMissionChapter() {
            return this.$store.getters.nowMissionChapter;
        },
        nowMissionSection() {
            return this.$store.getters.nowMissionSection;
        },
        nowMission() {
            return this.$store.getters.nowMissionChapter + "-" + this.$store.getters.nowMissionSection;
        }
    },
    watch: {
        isOpen(newVal) {
            if (!newVal) {
                this.$emit('close');
            }
        },
        hintBtnColor(newVal) {
            // console.log("newVal btn color", newVal)
            if (newVal == "orange") {
                this.hintBtnClass = "btn_orange";
            } else {
                this.hintBtnClass = "btn_blue";
            }
        }
    }
}
</script>