<template>
  <v-container>
    <div class="text-center">
      <v-img src="/images/ofuro_title.jpg"></v-img>
    </div>
    <div class="text-left pl-15 pr-15 pt-0 pb-0">
      期間限定で映画「ゴーストブック」とコラボしたお風呂が出現？！<br />
      おばけと一緒に写真を撮ろう！ぜひSNSにシェアしてね！
    </div>
    <div class="text-center">
      <v-img src="/images/ofuro_map.jpg"></v-img>
    </div>
    <div class="afterBase">
      <v-row class="text-center mt-0 pt-0 mb-0 pr-10 pl-10 afterCont">
        <v-col cols="12">
          映画「ゴーストブック」の<br />詳しい情報はこちら！
        </v-col>
        <v-col cols="12">
          <v-img src="/images/banner_movie.jpg" @click="goLink('https://ghostbook-movie.toho.co.jp/')"></v-img>
        </v-col>
        <v-col cols="12" class="mt-3 mb-0 pb-0">
          <v-btn width="80%" class="btn_orange mb-0" @click="goTop">
            TOPへ
          </v-btn>
        </v-col>

      </v-row>
    </div>
  </v-container>
</template>
<style scoped>
.afterCont {
  position: relative;
  max-width: 500px;
  top: -150px;
  height: 120px !important;

}
</style>
<script>

export default {
  data: () => ({}),
  methods: {
    goLink(url) {
      if (liff) {
        liff.openWindow({ "url": url, "external": true });
      }
    },
    goTop() {
      this.$router.push('/top');

    }
  },
  computed: {

  },
  watch: {

  }
};
</script>