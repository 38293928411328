<template>
  <v-container>
    <div :style="{ 'background-image': 'url(/images/yune_book_bg.jpg)' }" class="baseArea">
      <img src="/images/book_hint.png" width="130px" class="posmission1" v-if="nowMissionChapter <= 1" />
      <img src="/images/book_hint.png" width="130px" class="posmission2" v-if="nowMissionChapter <= 2" />
      <img src="/images/book_hint.png" width="130px" class="posmission3" v-if="nowMissionChapter <= 3" />
      <img src="/images/book_hint.png" width="130px" class="posmission4" v-if="nowMissionChapter <= 4" />
      <img src="/images/book_hint.png" width="130px" class="posmission5" v-if="nowMissionChapter <= 5" />
      <img src="/images/book_mission1.png" width="150px" height="126px"
        :class='[viewSeal && nowMissionChapter == 2 ? "posmission1anime" : ""]' class="posmission1"
        v-if="nowMissionChapter > 1" @click="$router.push('/mission1')" />
      <img src="/images/book_mission2.png" width="150px" height="129px"
        :class='[viewSeal && nowMissionChapter == 3 ? "posmission1anime" : ""]' class="posmission2"
        v-if="nowMissionChapter > 2" @click="$router.push('/mission2')" />
      <img src="/images/book_mission3.png" width="150px" height="146px"
        :class='[viewSeal && nowMissionChapter == 4 ? "posmission1anime" : ""]' class="posmission3"
        v-if="nowMissionChapter > 3" @click="$router.push('/mission3')" />
      <img src="/images/book_mission4.png" width="150px" height="126px"
        :class='[viewSeal && nowMissionChapter == 5 ? "posmission1anime" : ""]' class="posmission4"
        v-if="nowMissionChapter > 4" @click="$router.push('/mission4')" />
      <img src="/images/book_mission5.png" width="189px" height="178px"
        :class='[viewSeal && nowMissionChapter == 6 ? "posmission1anime" : ""]' class="posmission5"
        v-if="nowMissionChapter > 5" @click="$router.push('/mission5')" />
    </div>
    <div class="text-center pa-0">
      <v-btn width="80%" class="btn_orange" @click="$router.push('/map');">エリアマップへ</v-btn>
    </div>

    <v-dialog v-model="viewDialogStamp" @click="noneEvent" max-width="400" persistent>
      <v-img v-if="nowMissionChapter == 2 && nowMissionSection == 0" src="/images/yune_stamp_mission1.jpg"></v-img>
      <v-img v-if="nowMissionChapter == 3 && nowMissionSection == 0" src="/images/yune_stamp_mission2.jpg"></v-img>
      <v-img v-if="nowMissionChapter == 4 && nowMissionSection == 0" src="/images/yune_stamp_mission3.jpg"></v-img>
      <v-img v-if="nowMissionChapter == 5 && nowMissionSection == 0" src="/images/yune_stamp_mission4.jpg"></v-img>
      <v-img v-if="nowMissionChapter == 6 && nowMissionSection == 0" src="/images/yune_stamp_mission5.jpg"></v-img>
    </v-dialog>


    <v-dialog v-model="viewDialogInfo" max-width="400" persistent>
      <v-card>
        <v-card-text class="dialogText">
          <v-row class="pt-10">
            <v-col cols="12" class="mb-0 pb-0" v-if="nowMissionChapter == 2 && nowMissionSection == 0">
              ふぅ…無事に山彦を封印することに成功したな。ん？おばけ図鑑に新しい情報が出てきたぞ！<br />
              「どうやら、赤い水が沸いている場所にいるらしい…？」<br />
              とにかく次の場所へ行ってみるとするか〜。
            </v-col>
            <v-col cols="12" class="mb-0 pb-0" v-if="nowMissionChapter == 3 && nowMissionSection == 0">
              これでワイン風呂とコーヒー風呂が安全になったな。<br />
              ん？また新しいおばけの情報が！<br />
              「くらーい場所で目を光らせているらしい。。。？」<br />
              ちょっと怖いが、地図に示された場所に行ってみるか…。
            </v-col>
            <v-col cols="12" class="mb-0 pb-0" v-if="nowMissionChapter == 4 && nowMissionSection == 0">
              無事に百目を封印することに成功したな。<br />
              そういえば、ここには<span class="text-att">天国</span>のような場所があるそうだぞ。ちょっと行ってみないか？<br />
              え？「こんな忙しい時に」だって…？<br />
              そんなせかせかしてると体に毒だぞ。
            </v-col>
            <v-col cols="12" class="mb-0 pb-0" v-if="nowMissionChapter == 5 && nowMissionSection == 0">
              …ん？なんだか妙に息苦しいというか、どんよりしてきたな。温泉に来ていたお客さんたちが外へと逃げ出してくるぞ。<br />
              え！！あれは伝説のおばけ<span class="text-att">ジズリ</span>？！<br />
              早く封印しなくちゃ大変なことになっちゃう！！」
            </v-col>
            <v-col cols="12" class="mb-0 pb-0" v-if="nowMissionChapter == 6 && nowMissionSection == 0">
              こ、今度こそジズリを封印した…！！ これで正真正銘、箱根小涌園ユネッサンの平和を取り戻したぞ〜！！<br />
              周りのお客さんもお風呂でくつろげているみたいだ。良かったなぁ。<br />
              きっと支配人も喜んでくれるだろう。
            </v-col>
            <v-col cols="12" class="pa-0 text-center">
              <div :style="{ 'background-image': 'url(/images/map_zukanbo_info_bg.jpg)', 'height': '220px' }"
                class="text-center bgtop">
                <v-btn width="80%" class="btn_orange" style="margin-top:175px"
                  @click="viewDialogInfo = false; viewDialogPresent = true" v-if="nowMissionChapter == 6">
                  進む</v-btn>
                <v-btn width="80%" class="btn_orange" style="margin-top:175px" @click="$router.push('/map');" v-else>
                  エリアマップへ</v-btn>

              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewDialogPresent" max-width="400" persistent>
      <v-card>
        <v-card-text class="dialogText">
          <v-row class="pt-10">
            <v-col cols="12">
              <v-img src="/images/book_present.jpg"></v-img>
            </v-col>
            <v-col cols="12" class="pt-0 text-center">
              <v-btn width="80%" class="btn_orange" @click="goPresent">プレゼント</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-container>
</template>
<style scoped>
.bgtop {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
}

.baseArea {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;
}

.baseArea:before {
  content: "";
  display: block;
  padding-top: 153.79%;
}

.posmission1 {
  position: absolute;
  display: inline-block;
  top: 47%;
  left: 75%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.posmission2 {
  position: absolute;
  display: inline-block;
  top: 55%;
  left: 28%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.posmission3 {
  position: absolute;
  display: inline-block;
  top: 32%;
  left: 23%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.posmission4 {
  position: absolute;
  display: inline-block;
  top: 26.5%;
  left: 66.5%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.posmission5 {
  position: absolute;
  display: inline-block;
  top: 73%;
  left: 71%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.posmissionhide {
  opacity: 0;
}

.posmission1anime {
  animation-name: sealstamp;
  animation-delay: 0;
  animation-duration: 2.8s;
  animation-fill-mode: forwards;
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
}

@keyframes sealstamp {
  0% {
    opacity: 0;
    transform: rotate(-30deg) scale(1.5) translate(-50%, -50%);
    -webkit-transform: rotate(-30deg) scale(1.5) translate(-50%, -50%);
    -ms-transform: rotate(-30deg) scale(1.5) translate(-50%, -50%);
  }


  88% {
    opacity: 0;
    transform: rotate(-30deg) scale(1.5) translate(-50%, -50%);
    -webkit-transform: rotate(-30deg) scale(1.5) translate(-50%, -50%);
    -ms-transform: rotate(-30deg) scale(1.5) translate(-50%, -50%);
  }

  95% {
    opacity: 1;
    transform: rotate(0deg) scale(0.8) translate(-50%, -50%);
    -webkit-transform: rotate(0deg) scale(0.8) translate(-50%, -50%);
    -ms-transform: rotate(0deg) scale(0.8) translate(-50%, -50%);

  }

  100% {
    opacity: 1;
    transform: rotate(0deg) scale(1) translate(-50%, -50%);
    -webkit-transform: rotate(0deg) scale(1) translate(-50%, -50%);
    -ms-transform: rotate(0deg) scale(1) translate(-50%, -50%);

  }
}
</style>
<script>
export default {
  data: () => ({
    viewSeal: false,
    viewDialogStamp: false,
    viewDialogInfo: false,
    viewDialogPresent: false
  }),
  computed: {
    nowMissionChapter() {
      return this.$store.getters.nowMissionChapter;
    },
    nowMissionSection() {
      return this.$store.getters.nowMissionSection;
    },
    nowMission() {
      return this.$store.getters.nowMissionChapter + "-" + this.$store.getters.nowMissionSection;
    }
  },
  created: function () {
    scrollTo(0, 0);
    if (this.$route.params.seal === true) {
      this.viewDialogStamp = true;
      this.viewSeal = true;
    }

  },
  methods: {
    goPresent() {
      // this.$router.push({ name: 'present', params: { 'infoofuro': true } });
      this.$router.push('/present');
    },
    noneEvent() {
      console.log("none");
    }
  },
  watch: {
    viewSeal: function (newVal) {
      if (newVal) {
        setTimeout(() => {
          this.viewSeal = false;
          this.viewDialogInfo = true;
        }, 3100)
      }
    },
    viewDialogStamp: function (newVal) {
      if (newVal) {
        setTimeout(() => {
          this.viewDialogStamp = false;
        }, 2000)
      }
    }
  }
}
</script>