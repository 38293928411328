<template>
  <v-container>
    <div>
      <v-img src="/images/yune_map_start.jpg" v-if="nowMissionChapter == 0"></v-img>
      <v-img src="/images/yune_map_mission1_0.jpg" v-else-if="nowMissionChapter == 1 && nowMissionSection == 0"
        @click="goMission(1)"></v-img>
      <v-img src="/images/yune_map_mission1_1.jpg" v-else-if="nowMissionChapter == 1 && nowMissionSection >= 1"
        @click="goMission(1)"></v-img>
      <v-img src="/images/yune_map_mission2_0.jpg" v-else-if="nowMissionChapter == 2 && nowMissionSection == 0"
        @click="goMission(2)"></v-img>
      <v-img src="/images/yune_map_mission2_1.jpg" v-else-if="nowMissionChapter == 2 && nowMissionSection == 1"
        @click="goMission(2)"></v-img>
      <v-img src="/images/yune_map_mission2_2.jpg" v-else-if="nowMissionChapter == 2 && nowMissionSection >= 2"
        @click="goMission(2)"></v-img>
      <v-img src="/images/yune_map_mission3_0.jpg" v-else-if="nowMissionChapter == 3 && nowMissionSection == 0"
        @click="goMission(3)"></v-img>
      <v-img src="/images/yune_map_mission3_1.jpg" v-else-if="nowMissionChapter == 3 && nowMissionSection >= 1"
        @click="goMission(3)"></v-img>
      <v-img src="/images/yune_map_mission4_0.jpg" v-else-if="nowMissionChapter == 4 && nowMissionSection == 0"
        @click="goMission(4)"></v-img>
      <v-img src="/images/yune_map_mission4_1.jpg" v-else-if="nowMissionChapter == 4 && nowMissionSection == 1"
        @click="goMission(4)"></v-img>
      <v-img src="/images/yune_map_mission4_2.jpg" v-else-if="nowMissionChapter == 4 && nowMissionSection >= 2"
        @click="goMission(4)"></v-img>
      <v-img src="/images/yune_map_mission5_0.jpg" v-else-if="nowMissionChapter == 5 && nowMissionSection == 0"
        @click="goMission(5)"></v-img>
      <v-img src="/images/yune_map_mission5_1.jpg" v-else-if="nowMissionChapter == 5 && nowMissionSection == 1"
        @click="goMission(5)"></v-img>
      <v-img src="/images/yune_map_mission5_2.jpg" v-else-if="nowMissionChapter == 5 && nowMissionSection >= 2"
        @click="goMission(5)"></v-img>
      <v-img src="/images/yune_map_mission6_0.jpg" v-else-if="nowMissionChapter == 6 && nowMissionSection == 0"></v-img>
      <v-img src="/images/yune_map_mission6_1.jpg" v-else-if="nowMissionChapter == 6 && nowMissionSection == 1"
        @click="goMission6"></v-img>
      <v-img src="/images/yune_map_mission6_2.jpg" v-else-if="nowMissionChapter == 6 && nowMissionSection >= 2"
        @click="goMission6"></v-img>
      <v-img src="/images/yune_map_end.jpg" v-else></v-img>
    </div>

  </v-container>
</template>
<style scoped>
</style>
<script>

export default {
  data: () => ({}),
  methods: {
    goMission(num) {
      if (this.nowMissionChapter == num && this.nowMissionSection == 0) {
        this.$store.commit("setMissionSection", 1);
      }
      this.$router.push('/mission' + num);
    },
    goMission6() {
      if (this.nowMissionChapter == 6 && this.nowMissionSection == 1) {
        this.$store.commit("setMissionSection", 2);
      }
      this.$router.push('/mission6');
    }

  },
  computed: {
    nowMissionChapter() {
      return this.$store.getters.nowMissionChapter;
    },
    nowMissionSection() {
      return this.$store.getters.nowMissionSection;
    },
    nowMission() {
      return this.$store.getters.nowMissionChapter + "-" + this.$store.getters.nowMissionSection;
    }
  },
  watch: {
  }
};
</script>