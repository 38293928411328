import Vue from 'vue'
import VueRouter from 'vue-router'

import LoadingView from '../views/LoadingView.vue'
import TopView from '../views/TopView.vue'
import MapView from '../views/MapView.vue'
import BookView from '../views/BookView.vue'
import Mission1View from '../views/Mission1View.vue'
import Mission2View from '../views/Mission2View.vue'
import Mission3View from '../views/Mission3View.vue'
import Mission4View from '../views/Mission4View.vue'
import Mission5View from '../views/Mission5View.vue'
import Mission6View from '../views/Mission6View.vue'
import PresentView from '../views/PresentView.vue'
import OfuroView from '../views/OfuroView.vue'
import ErrorView from '../views/ErrorView.vue'
import MaintenanceView from '../views/MaintenanceView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'loading',
    component: LoadingView
  },
  {
    path: '/top',
    name: 'top',
    component: TopView
  },
  {
    path: '/map',
    name: 'map',
    component: MapView
  },
  {
    path: '/book',
    name: 'book',
    component: BookView
  },
  {
    path: '/mission1',
    name: 'mission1',
    component: Mission1View
  },
  {
    path: '/mission2',
    name: 'mission2',
    component: Mission2View
  },
  {
    path: '/mission3',
    name: 'mission3',
    component: Mission3View
  },
  {
    path: '/mission4',
    name: 'mission4',
    component: Mission4View
  },
  {
    path: '/mission5',
    name: 'mission5',
    component: Mission5View
  },
  {
    path: '/mission6',
    name: 'mission6',
    component: Mission6View
  },
  {
    path: '/present',
    name: 'present',
    component: PresentView
  },
  {
    path: '/ofuro',
    name: 'ofuro',
    component: OfuroView
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: MaintenanceView
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorView
  },
  {
    path: '*',
    name: 'error',
    component: ErrorView
  }
]

const router = new VueRouter({
  mode: 'history',
  // mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
