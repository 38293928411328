<template>
  <v-container>

    <v-row class="text-center pt-5 pl-10 pr-10">
      <v-col cols="12">
        <img src="/images/yunessan_title.png" /><br />
      </v-col>
      <v-col cols="12">
        <h1>エラーが発生しました</h1>
      </v-col>
      <v-col cols="12">
        {{ errormessage }}
      </v-col>
      <v-col cols="12">
        一度この画面を閉じて再度立ち上げ直しをしてください。
      </v-col>
    </v-row>
  </v-container>

</template>
<script>
export default {
  data: () => ({
    errormessage: ""
  }),
  created: function () {
    if (this.$route.params.errormessage) {
      this.errormessage = this.$route.params.errormessage;
    }
  }
}
</script>
