<template>
  <v-container>
    <div class="text-center topcomment pt-5">

      <img src="/images/yunessan_title.png" /><br />

      <br />
      ゴーストブックのおばけたちが<br />
      ユネッサンに住み着き<br />
      悪さをしている！<br />
      <br />
      この温泉に訪れたあなたは、<br />
      ひょんなことから<br />
      おばけを封印できる力を<br />
      手に入れた。<br />
      <br />
      温泉の支配人から<br />
      「おばけを封印して、<br />
      みんなが笑顔で楽しい<br />
      思い出を残して欲しい。」と<br />
      頼まれたあなた。<br />
      <br />
      この地の秘密を知ると言う<br />
      図鑑坊の力を借りて、<br />
      おばけの住まう<br />
      場所へと向かい、<br />
      ユネッサンの日常を<br />
      取り戻せ！
    </div>
    <v-row class="mt-15">
      <v-col cols="12" class="ma-0 pb-0 pt-0 pl-5 pr-5">
        <v-img src="/images/yune_ghostbook_logo.png" />
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col class="ma-0 pa-0 pl-5 pr-5" @click="maintenanceCheck(0)">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
          viewBox="0 0 369 522">
          <image href="/images/top-key_01.png" width="369" height="11" x="0" y="0" />
          <image href="/images/top-key_02.png" width="10" height="511" x="0" y="11" />
          <image href="/images/top-key_03.jpg" width="349" height="501" x="10" y="11" />
          <image href="/images/top-key_04.png" width="10" height="511" x="359" y="11" />
          <image href="/images/top-key_05.png" width="349" height="10" x="0" y="511" />
        </svg>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col class="mb-12">
        <v-btn @click="dialogGoMission = true" class="btn_orange" v-if="nowMissionChapter == 0">謎解きを始める</v-btn>
        <v-btn @click="$router.push('/map');" class="btn_orange" v-else>エリアマップへ</v-btn>
      </v-col>
    </v-row>

    <!-- Mission<br />
    <v-btn @click='$store.commit("setNextMission", 0)'>0</v-btn>
    <v-btn @click='$store.commit("setNextMission", 1)'>1</v-btn>
    <v-btn @click='$store.commit("setNextMission", 2)'>2</v-btn>
    <v-btn @click='$store.commit("setNextMission", 3)'>3</v-btn>
    <v-btn @click='$store.commit("setNextMission", 4)'>4</v-btn>
    <v-btn @click='$store.commit("setNextMission", 5)'>5</v-btn>
    <v-btn @click='$store.commit("setNextMission", 6)'>6</v-btn>
    <hr />
    Section<br />
    <v-btn @click='$store.commit("setMissionSection", 1)'>1</v-btn>
    <v-btn @click='$store.commit("setMissionSection", 2)'>2</v-btn>
    <v-btn @click='$store.commit("setMissionSection", 3)'>3</v-btn>
    <v-btn @click='$store.commit("setMissionSection", 4)'>4</v-btn>
    <v-btn @click='$store.commit("setMissionSection", 5)'>5</v-btn>
    <hr />
    保存データ<br />
    <v-btn @click='deleteUser'>Del</v-btn>
    <hr />
    <hr /> -->


    <v-row class="ma-3" max-width="200">
      <v-col cols="12" class="text-center" style="font-weight: bold;" @click="maintenanceCheck(1)">
        謎解きを<br />
        お楽しみいただくにあたって
      </v-col>
      <v-col cols="12" v-if="$store.getters.isMaintenance">
        <hr />
      </v-col>
      <v-col cols="12" style="font-size:0.8em;" @click="maintenanceCheck(2)">
        <ul>
          <li>LINEと連携して謎解きの進行を保存しています。必ずLINE内ブラウザを使用してお楽しみいただきますようお願いいたします。</li>
          <li>謎解きの進行で利用するQRコードの読み取りやカメラ撮影、音声認識を利用するため、カメラとマイクの利用は許諾をお願いいたします。
          </li>
          <li>本コンテンツご利用時のデータ通信量（パケット）はお客様負担となります。通信についての契約確認のうえでご利用ください。
          </li>
          <li>OSとLINEのバージョンは最新版を推奨しております。iPadでは参加できない場合がございます。</li>
          <li>これからお楽しみいただくお客様のために、謎の問題、解答やヒントをブログやSNSなど、インターネットで公開しないでください。遊んでみての感想は大歓迎です。</li>
          <li>歩きながらのスマートフォンの使用は危険です。スマートフォン等の操作は周囲の安全を確認し、止まってご利用ください。</li>
        </ul>
      </v-col>
    </v-row>
    <v-row class="text-center mt-12 mb-12">
      <v-col cols="12">
        <v-btn @click="dialogGoMission = true" class="btn_orange" v-if="nowMissionChapter == 0">謎解きを始める</v-btn>
        <v-btn @click="$router.push('/map');" class="btn_orange" v-else>エリアマップへ</v-btn>
      </v-col>
    </v-row>
    <v-row class="text-center mt-12 mb-12">
      <v-col cols="12">
        <v-img src="/images/banner_movie.jpg" @click="goLink('https://ghostbook-movie.toho.co.jp/')"></v-img>
      </v-col>
      <v-col cols="12">
        <v-img src="/images/banner_yu.jpg" @click="goLink('https://www.yunessun.com/')"></v-img>
      </v-col>
      <v-col cols="12">
        <v-img src="/images/banner_ghostbook.png"></v-img>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogTos" persistent max-width="400">
      <v-card>
        <v-card-title class="justify-center" style="font-weight: bold;">お楽しみいただくに当たって</v-card-title>

        <v-card-text class="dialogText">
          <v-row>
            <v-col cols="12" class="mb-0 pb-0">
              ・この謎解きはLINEと連携しながら進行を保存しています。必ずLINE内ブラウザを使用してお楽しみください。
            </v-col>
            <v-col cols="12" class="mt-0 pt-5 mb-0">
              ・謎解きの進行で利用するQRコードの読み取りやカメラ撮影、音声認識を利用するため、カメラとマイクの利用は許諾をお願いいたします。
            </v-col>
            <v-col cols="12" class="mt-0 pt-5 mb-5">
              ・本コンテンツご利用時のデータ通信量（パケット）はお客様負担となります。通信についての契約確認のうえでご利用ください。
            </v-col>

          </v-row>
          <div class="text-center">
            <v-alert v-model="checkPermission">
              <span>動作環境を確認中</span>
            </v-alert>
            <v-alert v-model="permissonError" type="error">
              <span>カメラやマイクが利用できません。一旦閉じて再度開き許可を出す設定に変更してください。</span>
            </v-alert>
            <v-alert v-model="liffError" type="error">
              LINEのアプリ内で開いてください。
            </v-alert>
            <v-btn class="btn_orange" @click="permissionOk" :disabled="checkPermission">
              了解しました
            </v-btn>
          </div>

        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog v-model="dialogGoMission" max-width="400">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="dialogText">
          <v-row>
            <v-col cols="12" class="pt-0" style="min-height:180px;display: flex;align-items: center;">
              <div>
                支配人から「<span class="text-att">超絶景展望露天風呂</span>で妙な言葉が飛び交っているらしい…」と聞いたあなた。<br />
                まずはその場所へ向かい、おばけを封印しよう。
              </div>
            </v-col>
            <v-col cols="12" class="pa-0 text-center">
              <v-btn class="btn_orange" @click="goMission">
                エリアマップへ
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-container>
</template>
<style scoped>
.topcomment {
  font-family: 'Shippori Mincho', serif;
}
</style>
<script>
import HelpWindow from "./components/HelpWindow";
// import axios from "axios";

export default {
  name: "Top",
  data: () => ({
    dialogTos: false,
    dialogGoMission: false,
    checkPermission: false,
    permissonError: false,
    liffError: false,
    liffProfile: null,
    maintenanceCount: 0
  }),
  components: {
    HelpWindow
  },
  created: async function () {
    // 最初のダイアログを表示するかどうか
    this.dialogTos = !this.$store.getters.isCheckTos;

    if (this.$store.getters.isCheckTos == true && this.$store.getters.checkDevice == false) {
      // アラートが出ない場合はいきなりデバイスの許可を取る
      try {
        await this.$stamp.checkUseDevice();
      } catch (error) {
        this.$router.push({ name: 'error', params: { 'errormessage': "カメラなどのデバイスの利用ができません。" } });
      }
      this.$store.commit("checkDevice", true);
    }

    this.maintenanceCount = 0;

    // preload
    const preloadImages = ["/images/yune_stamp_mission1.jpg", "/images/yune_stamp_mission2.jpg", "/images/yune_stamp_mission3.jpg", "/images/yune_stamp_mission4.jpg", "/images/yune_stamp_mission5.jpg", "/images/mission5_tengoku.png"];

    for (let key of preloadImages) {
      let preload = new Image();
      preload.src = key;
    }

  },
  destroyed: function () {
  },
  methods: {
    goMission() {
      this.$store.commit("setNextMission", 1);
      this.$router.push('/map');
    },
    goLink(url) {
      if (liff) {
        liff.openWindow({ "url": url, "external": true });
      }
    },
    async permissionOk() {
      this.$store.commit("checkDevice", true);
      this.$store.commit("setTos", true);

      try {
        this.checkPermission = true;
        this.permissonError = false;
        try {
          await this.$stamp.checkUseDevice();
        } catch (error) {
          this.$router.push({ name: 'error', params: { 'errormessage': "カメラなどのデバイスの利用ができません。" } });
        }
        this.checkPermission = false;
        this.dialogTos = false;

      } catch (e) {
        this.checkPermission = false;
        this.permissonError = true;
        console.log(e);
      }
    },
    // deleteUser() {
    //   const delData = {
    //     lineUserHashKey: this.$store.getters.lineUserHashKey,
    //     "deletePassKey": "yunessan delete abcde1234",
    //     localUserHashKey: this.$store.getters.localUserHashKey
    //   }
    //   localStorage.removeItem(this.$store.getters.localStorageKey);
    //   localStorage.removeItem(this.$store.getters.localStorageKey + "_key");
    //   axios.delete(
    //     'https://9o49cj3vk3.execute-api.ap-northeast-1.amazonaws.com/prod/user',
    //     { data: delData })
    //     .then((res) => {
    //       // console.log("☆ServerRes", res);
    //       location.reload(true);
    //     })
    //     .catch((error) => {
    //       console.error("☆☆ServerERROR", error);
    //     });
    //   //https://9o49cj3vk3.execute-api.ap-northeast-1.amazonaws.com/prod/user
    // }
    maintenanceCheck(num) {
      if (num == 0) {
        this.maintenanceCount = 0;
      } else if (num == 1 && this.maintenanceCount % 4 < 2) {
        this.maintenanceCount++;
      } else if (num == 2 && this.maintenanceCount % 4 >= 2) {
        this.maintenanceCount++;
      } else {
        this.maintenanceCount = 0;
      }
      // console.log(num, this.maintenanceCount, this.maintenanceCount % 4)
      if (this.maintenanceCount == 6) {
        console.log("maintenance mode on");
        this.$store.commit("setMaintenance", true);
        this.maintenanceCount = 0;
      }
      // if (num == 0 && (this.maintenanceCount == 0 || this.maintenanceCount == 3)) {
      //   this.maintenanceCount++;
      // } else if (num == 1 && (this.maintenanceCount == 1 || this.maintenanceCount == 4)) {
      //   this.maintenanceCount++;
      // } else if (num == 2 && (this.maintenanceCount == 2 || this.maintenanceCount == 5)) {
      //   this.maintenanceCount++;
      //   if (this.maintenanceCount == 6) {
      //     console.log("maintenance mode on");
      //     this.$store.commit("setMaintenance", true);
      //     this.maintenanceCount = 0;
      //   }
      // } else {
      //   this.maintenanceCount = 0;
      // }
    }
  },
  computed: {
    nowMissionChapter() {
      return this.$store.getters.nowMissionChapter;
    },
    nowMissionSection() {
      return this.$store.getters.nowMissionSection;
    },
    nowMission() {
      return this.$store.getters.nowMissionChapter + "-" + this.$store.getters.nowMissionSection;
    }
  },
  watch: {

  }
};
</script>
