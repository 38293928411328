<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center">
        <img src="/images/yune_title_mission5.png" width="70%" />
      </v-col>
      <v-col cols="12" class="text-left pl-10 pr-10">
        <span class="text-att">神々のエーゲ海</span>の奥地にたどり着く。<br />
        今まで封印してきたおばけたちが画面上で震えている…！？図鑑坊「こいつは、ジズリという時空をも歪ます凶暴なおばけなんだ…これまで封印した4体のおばけと一緒に戦って勝てるかどうか…」<br />
        ジズリに勇敢に立ち向かい、封印してユネッサンの平和を取り戻せ！それをできるのはあなただけだ！
      </v-col>
    </v-row>

    <v-row v-if="nowMissionChapter > 5 || (nowMissionChapter == 5 && nowMissionSection >= 1)">
      <v-col cols="12" class="text-center">
        <div class="nazoML">
          <div style="width:100%">
            <v-row class="text-center">
              <v-col cols="12" class="pb-2" style="font-weight: bold;">
                おばけを順に召喚せよ。
              </v-col>
              <v-col cols="12" class="pr-2 pl-2 pt-0 pb-0 text-center">
                <img src="/images/mission5_s1image.png" />
              </v-col>
            </v-row>
            <v-row class="text-center pt-2" v-if="nowMissionChapter == 5 && nowMissionSection == 1">
              <v-col cols="6" class="pt-0">
                <v-btn width="100%" class="btn_orange" @click="viewDialogS1 = true">答える</v-btn>
              </v-col>
              <v-col cols="6" class="pt-0">
                <v-btn width="100%"
                  :class="$store.getters.useHintList.indexOf(nowMission) == -1 ? 'btn_blue' : 'btn_orange'"
                  @click="s1Hint">ヒントを聞く</v-btn>
              </v-col>
            </v-row>
            <v-row class="text-center pt-2" v-else>
              <v-col class="answerTitle">
                答え
              </v-col>
              <v-col cols="9" class="answerArea">
                C→A→B→D
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="nowMissionChapter > 5 || (nowMissionChapter == 5 && nowMissionSection >= 2)">
      <v-col cols="12" class="text-left pl-10 pr-10">
        今まで封印したおばけたちの力を結集して、さぁ攻撃だ！！
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn class="btn_orange" @click="viewDialogS2 = true">ムービーを見る</v-btn>
      </v-col>
    </v-row>

    <v-row v-if="nowMissionChapter > 5 || (nowMissionChapter == 5 && nowMissionSection >= 3)">
      <v-col cols="12" class="text-left pl-10 pr-10">
        「はぁはぁはぁはぁ。」<br />
        かなり体力を消耗したけど、おばけたちと力を合わせてジズリを倒したぞ！疲れとともに仲間の栄誉を讃えあうみんな。<br />
        「こ、これでユネッサンも平和なば…しょ…に…」<br />
        と思ったら…ジズリのやつ、最後の力を振り絞ってどこかに姿を消したのか？！<br />
        「<span class="text-att">天国</span>」ってどこだっけ？！
      </v-col>
      <v-col cols="12">
        <div class="nazoML">
          <div style="width:100%">
            <v-row class="text-center pt-0 pb-0 mb-0">
              <v-col cols="12" style="font-weight: bold;">
                天国へ行き、QRを読め。
              </v-col>
              <v-col cols="12" class="pr-7 pl-7 pt-0 pb-0">
                <v-img src="/images/mission5_jizuri.png"></v-img>
              </v-col>
            </v-row>
            <v-row class="text-center" v-if="nowMissionChapter == 5 && nowMissionSection == 3">
              <v-col cols="6" class="pt-0">
                <v-btn width="100%" class="btn_orange" @click="viewDialogS3 = true">QRを読む</v-btn>
              </v-col>
              <v-col cols="6" class="pt-0">
                <v-btn width="100%"
                  :class="$store.getters.useHintList.indexOf(nowMission) == -1 ? 'btn_blue' : 'btn_orange'"
                  @click="s3Hint">ヒントを聞く</v-btn>
              </v-col>
            </v-row>
            <v-row class="text-center" v-else>
              <v-col class="answerTitle">
                答え
              </v-col>
              <v-col cols="9" class="answerArea">
                玉座のQR
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="text-center pa-10">
      <v-btn width="80%" class="btn_orange" @click="$router.push('/map');">エリアマップへ</v-btn>
    </div>

    <!---------------------->

    <QrWindow v-if="viewDialogS3" @close="viewDialogS3 = false" @correct="qrAnswerOk"></QrWindow>

    <!---------------------->
    <v-dialog v-model="viewDialogS1" max-width="400">
      <v-card>
        <v-card-title class="dialogText text-center">
          <v-col cols="12" v-if="!isS1AnswerError" style="font-weight: bold;">
            おばけを順に召喚せよ。
          </v-col>
          <v-col cols="12" v-else>
            残念！答えが<br />
            違っているようだ
          </v-col>
        </v-card-title>
        <v-card-text class="dialogText">
          <v-row class="text-center" v-if="!isS1AnswerError">
            <v-col cols="6" :class="s1AnswerStyleA" class="ma-0 pa-0">
              <v-img src="/images/mission5_s1_a.jpg" @click="s1AnswerBtn('a')" class="s2btn" />
            </v-col>
            <v-col cols="6" :class="s1AnswerStyleB" class="ma-0 pa-0">
              <v-img src="/images/mission5_s1_b.jpg" @click="s1AnswerBtn('b')" class="s2btn" />
            </v-col>
            <v-col cols="6" :class="s1AnswerStyleC" class="ma-0 pa-0">
              <v-img src="/images/mission5_s1_c.jpg" @click="s1AnswerBtn('c')" class="s2btn" />
            </v-col>
            <v-col cols="6" :class="s1AnswerStyleD" class="ma-0 pa-0">
              <v-img src="/images/mission5_s1_d.jpg" @click="s1AnswerBtn('d')" class="s2btn" />
            </v-col>

            <!-- <v-col cols="12" v-if="s1AnswerErrorMessage" class="s1AnswerError">
              {{ s1AnswerErrorMessage }}
            </v-col>

            <v-col cols="6" v-if="s1AnswerErrorMessage" class="pt-0">
              <v-btn @click="s1AnswerReset" class="btn_orange">もう一度</v-btn>
            </v-col> -->
            <v-col class="pt-5">
              <v-btn :class="$store.getters.useHintList.indexOf(nowMission) == -1 ? 'btn_blue' : 'btn_orange'"
                @click="s1Hint">ヒントを聞く</v-btn>
            </v-col>
          </v-row>

          <v-row class="text-center" v-else>
            <v-col cols="12" class="pt-5">
              <v-btn width="80%" @click="s1AnswerReset" class="btn_orange">もう一度答える</v-btn>
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-btn width="80%" @click="s1Hint"
                :class="$store.getters.useHintList.indexOf(nowMission) == -1 ? 'btn_blue' : 'btn_orange'">
                ヒントを聞く
              </v-btn>
            </v-col>
          </v-row>

          <div class="correct" v-if="correctAnime">
            <v-img src="/images/correct.svg"></v-img>
          </div>

        </v-card-text>
      </v-card>
    </v-dialog>
    <!---------------------->

    <MovieWindow v-if="viewDialogS2" @close="viewDialogS2 = false" :movie="characteristicVideo"></MovieWindow>

    <!---------------------->
    <v-dialog v-model="viewDialogS2_2" max-width="400" persistent>
      <v-card>
        <v-card-text class="dialogText ">
          <v-row class="pt-10">
            <v-col cols="12" class="mb-0 pb-0" v-if="!viewDialogS2_4">
              はぁはぁ…<br />
              かなり体力を消耗したけど、おばけたちと力を合わせてジズリを倒したぞ！これでユネッサンも平和な場所に…
            </v-col>
            <v-col cols="12" class="mb-0 pb-0" v-if="viewDialogS2_4">
              びっくりしたー！<br />
              ジズリのやつ、最後の力を振り絞ってどこかに姿を消したのか？！<br />
              「<span class="text-att">天国</span>」ってどこだっけ…？！<br />
            </v-col>
            <v-col cols="12" class="pa-0 text-center">
              <div :style="{ 'background-image': 'url(/images/map_zukanbo_info_bg.jpg)', 'height': '230px' }"
                class="text-center bgtop">
                <v-btn width="80%" class="btn_orange" v-if="!viewDialogS2_4" style="margin-top:175px"
                  @click="viewDialogS2_3 = true">進む
                </v-btn>
                <v-btn width="80%" class="btn_orange" v-if="viewDialogS2_4" style="margin-top:175px"
                  @click="s2ScenarioEnd">閉じる
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!---------------------->
    <div class="s2View" :style="{ 'background-image': 'url(/images/mission5_tengoku.png)', 'z-index': 999 }"
      v-show="viewDialogS2_3 === true">
    </div>

    <!---------------------->

    <HelpWindow :message="hintMessage" @close="hintMessage = null"></HelpWindow>

    <!---------------------->
    <!-- <div v-if="$store.getters.isMaintenance">
      <v-btn @click='$store.commit("setNextMission", 5); $store.commit("setMissionSection", 1)'>1</v-btn>
      <v-btn @click='$store.commit("setNextMission", 5); $store.commit("setMissionSection", 2)'>2</v-btn>
      <v-btn @click='$store.commit("setNextMission", 5); $store.commit("setMissionSection", 3)'>3</v-btn>
      <v-btn @click='missionClear()'>END</v-btn>
    </div> -->

  </v-container>
</template>
<style scoped>
.bgtop {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
}


.s2View {
  position: fixed;
  background-color: rgba(30, 30, 30, 0.7);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  animation: pluplu .1s 5;
}

@keyframes pluplu {
  0% {
    transform: translate(0px, 0px) rotateZ(0deg)
  }

  25% {
    transform: translate(2px, 2px) rotateZ(1deg)
  }

  50% {
    transform: translate(0px, 2px) rotateZ(0deg)
  }

  75% {
    transform: translate(2px, 0px) rotateZ(-1deg)
  }

  100% {
    transform: translate(0px, 0px) rotateZ(0deg)
  }
}

.s2btn {
  cursor: pointer;
}

.s1num1::after {
  content: "１";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 3em;
  font-weight: bold;
}

.s1num1 {
  position: relative;
}

.s1num1>div {
  opacity: 0.5;
  cursor: not-allowed;
}

.s1num2::after {
  content: "２";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 3em;
  font-weight: bold;
}

.s1num2 {
  position: relative;
}

.s1num2>div {
  opacity: 0.5;
  cursor: not-allowed;
}

.s1num3::after {
  content: "３";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 3em;
  font-weight: bold;
}

.s1num3 {
  position: relative;
}

.s1num3>div {
  opacity: 0.5;
  cursor: not-allowed;
}

.s1num4::after {
  content: "４";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 3em;
  font-weight: bold;
}

.s1num4 {
  position: relative;
}

.s1num4>div {
  opacity: 0.5;
  cursor: not-allowed;
}

.s1AnswerError {
  position: absolute;
  top: 200px;
  left: 0;
  font-size: 2em;
  font-weight: bold;
  color: #990000;
}

.correctBase {
  position: relative;
}

.correct {
  position: absolute;
  animation-name: correctfade;
  animation-duration: 1400ms;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  opacity: 0;
}

.correct>* {
  margin: 160px auto 0;
  width: 33%;
}

@keyframes correctfade {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}
</style>
<script>
import HelpWindow from "./components/HelpWindow";
import QrWindow from "./components/QrWindow";
import MovieWindow from "./components/MovieWindow";

export default {
  components: {
    HelpWindow,
    QrWindow,
    MovieWindow
  },
  data: () => ({
    // viewDialogQr: false,
    viewDialogS1: false,
    viewDialogS2: false,
    viewDialogS2_2: false,
    viewDialogS2_3: false,
    viewDialogS2_4: false,
    viewDialogS3: false,
    s1AnswerList: [],
    isS1AnswerError: false,
    s1AnswerStyleA: null,
    s1AnswerStyleB: null,
    s1AnswerStyleC: null,
    s1AnswerStyleD: null,
    characteristicVideo: null,
    hintMessage: null,
    correctAnime: false
  }),
  created: function () {
    let preload = new Image();
    preload.src = "/images/mission5_tengoku.png";

    if (this.nowMissionChapter < 5) {
      this.$router.push('/');
    } else if (this.nowMissionChapter == 5 && this.nowMissionSection == 0) {
      this.$router.push('/map');
    }

    this.characteristicVideo = document.createElement("video");
    this.characteristicVideo.src = "movies/mission5_1.mp4";
  },

  methods: {
    qrAnswerOk() {
      this.viewDialogQr = false;
      this.missionClear();
    },
    s1AnswerBtn(code) {
      const okList = ["c", "a", "b", "d"];
      if (this.s1AnswerList.indexOf(code) == -1) {
        this.s1AnswerList.push(code);
        switch (code) {
          case "a":
            this.s1AnswerStyleA = "s1num" + this.s1AnswerList.length;
            break;
          case "b":
            this.s1AnswerStyleB = "s1num" + this.s1AnswerList.length;
            break;
          case "c":
            this.s1AnswerStyleC = "s1num" + this.s1AnswerList.length;
            break;
          case "d":
            this.s1AnswerStyleD = "s1num" + this.s1AnswerList.length;
            break;
        }
      }

      if (this.s1AnswerList.length == 4) {
        let checkNg = false;
        for (let i in this.s1AnswerList) {
          if (okList[i] != this.s1AnswerList[i]) {
            checkNg = true;
          }
        }
        if (!checkNg) {
          this.correctAnime = true;
          setTimeout(() => {
            this.s1AnswerReset();
            this.$store.commit("setMissionSection", 2);
            this.viewDialogS1 = false;
          }, 1300);
        } else {
          this.isS1AnswerError = true;
          gtag('event', 'miss', { 'event_category': this.nowMission, 'event_label': this.s1AnswerList.join(">") });
        }
      }
    },
    s1AnswerReset() {
      this.s1AnswerList = [];
      this.isS1AnswerError = false;
      this.s1AnswerStyleA = null;
      this.s1AnswerStyleB = null;
      this.s1AnswerStyleC = null;
      this.s1AnswerStyleD = null;
    },

    s2ScenarioEnd() {
      this.viewDialogS2_2 = false;
      this.viewDialogS2_4 = false;
      if (this.nowMissionChapter == 5) {
        this.$store.commit("setMissionSection", 3);
      }
    },
    missionClear() {
      this.$store.commit("setNextMission", 6);
      this.$router.push({ name: 'book', params: { 'seal': true } });
    },

    s1Hint() {
      this.hintMessage = "数字を文字に変えられる表をどこかで使わなかったかな？？MISSSION2で使ったような…。<br />その特技を使うお化けを順番に召喚してみよう！";
    },
    s3Hint() {
      this.hintMessage = "「ここは天国だ」って誰かが言ってなかったかな……。よーく思い出してみて！<br/>どこかの椅子に座っていたよ！";
    }
  },
  computed: {
    nowMissionChapter() {
      return this.$store.getters.nowMissionChapter;
    },
    nowMissionSection() {
      return this.$store.getters.nowMissionSection;
    },
    nowMission() {
      return this.$store.getters.nowMissionChapter + "-" + this.$store.getters.nowMissionSection;
    }
  },
  watch: {
    viewDialogS1(newVal) {
      if (!newVal) {
        this.correctAnime = false;
      }
    },
    viewDialogS2(newVal) {
      if (!newVal) {
        if (this.nowMissionChapter == 5 && (this.nowMissionSection == 2 || this.nowMissionSection == 3)) {
          this.viewDialogS2_2 = true;
        }
      }
    },
    viewDialogS2_3(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.viewDialogS2_3 = false;
          this.viewDialogS2_4 = true;
        }, 3000)
      }
    },
  }
};
</script>
