<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12" class="mt-5 pb-2">
        <img src="/images/present_title.png" width="100%" />
      </v-col>
    </v-row>
    <v-row class="text-center pl-5 pr-5" v-if="nowMissionChapter <= 5">
      <v-col cols="12">
        おばけずかんをコンプリートするとプレゼントが表示されるよ！
      </v-col>
    </v-row>

    <v-row class="text-center pl-5 pr-5" v-if="nowMissionChapter >= 7">
      <v-col cols="12" class="text-center">
        プレゼント一覧
      </v-col>
      <v-col class="pa-0 ma-0">
        <v-btn text color="white" @click="scrollToAnchorPoint('photo')">
          <v-icon size="16px">mdi-gift</v-icon>&nbsp;
          <span style="text-decoration:underline;">フォトフレーム</span>
        </v-btn>
      </v-col>
      <v-col class="pa-0 ma-0">
        <v-btn text color="white" @click="scrollToAnchorPoint('sticker')">
          <v-icon size="16px">mdi-gift</v-icon>&nbsp;
          <span style="text-decoration:underline;">オリジナルステッカー</span>
        </v-btn>
      </v-col>
      <v-col cols="12" class="pa-0 ma-0 ma-3 mb-3">
        <hr width="80%" class="barstyle" />
      </v-col>
    </v-row>


    <v-row class="text-center" v-if="nowMissionChapter >= 6">
      <v-col cols="12" class="pt-0 pl-5 pr-5 pb-0">
        おばけずかんを<br />
        コンプリートした君に…
      </v-col>
      <v-col cols="12" class="pt-1 pb-1" style="font-size:1.4em;font-weight: bold;" ref="photo">
        フォトフレーム
      </v-col>
      <v-col cols="12" class="pt-3 pb-3">
        <div class="photoframe">
          <v-img src="/images/present_preimage.jpg" width="50%" class="prephoto"></v-img>
        </div>
      </v-col>
      <v-col cols="12" class="text-left pt-2 pl-12 pr-12">
        5つのミッションを見事クリアした君にフォトフレームをプレゼント！ユネッサンでの思い出を残そう！
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn class="btn_orange" @click="viewDialogShot = true">このフレームで写真を撮る</v-btn>
      </v-col>
    </v-row>

    <v-row class="text-center" v-if="nowMissionChapter >= 7">
      <v-col cols="12" class="pa-0 ma-0 ma-3 mb-3">
        <hr width="80%" class="barstyle" />
      </v-col>

      <v-col cols="12" class="pl-5 pr-5">
        おばけずかんを<br />
        完全にコンプリートした君に…
      </v-col>
      <v-col cols="12" class="text-center" style="font-size:1.4em;font-weight: bold;" ref="sticker">
        オリジナルステッカー
      </v-col>
      <v-col cols="12" class="pl-12 pr-12">
        <v-img src="/images/present_sticker.jpg"></v-img>
      </v-col>
      <v-col cols="12" class="text-left pl-12 pr-12">
        出口カウンターでこの画面を見せると、映画オリジナルステッカーがもらえるよ！
      </v-col>
      <v-col cols="12" class="pl-12 pr-12">
        <v-img src="/images/present_counter.jpg"></v-img>
      </v-col>
      <v-col cols="12" class="text-left pl-12 pr-12 pb-10">
        洋服に着替えてから、出口にあるこのカウンターで受け取ってね！<br />
        ※受付時間は20時までです。
      </v-col>
    </v-row>

    <!---------------------->
    <v-dialog v-model="viewDialogShot" max-width="400">
      <v-card>
        <div class="text-right mr-1">
          <v-icon @click="viewDialogShot = false">mdi-close</v-icon>
        </div>
        <v-card-text class="dialogText">
          <v-row class="pa-0">
            <v-col cols="12" class="text-center" v-show="!isArchives">
              <img ref="capImage" width="100%" src="/images/yunessan_frame.png" style="background-color:#aaa" />
              <div class="text-right">
                <v-icon size="2em" class="ma-0 pa-0" @click="switchCamera">mdi-camera-flip-outline</v-icon>
              </div>
            </v-col>

            <v-col cols="12" class="text-center" v-show="isArchives">
              <img ref="shotImage" width="100%" />
            </v-col>

            <v-col cols="12" class="text-center mt-0 pt-0" v-if="!isArchives">
              <v-btn width="80%" class="btn_orange" :disabled="!isActiveCamera" @click="execShot">撮影する</v-btn>
            </v-col>

            <v-col cols="12" class="text-center" v-if="isArchives && sendShotStatus == 9">
              <v-alert type="error">
                <span>送信できませんでした。<br />再度お試しください。</span>
              </v-alert>
            </v-col>

            <v-col cols="6" class="text-center" v-if="isArchives">
              <v-btn width="80%" class="btn_blue" @click="isArchives = false">もう一度撮影する</v-btn>
            </v-col>
            <v-col cols="6" class="text-center" v-if="isArchives">
              <div v-if="isLineApp">
                <v-btn width="80%" :disabled="sendShotStatus != 0 && sendShotStatus != 9" class="btn_orange"
                  @click="saveShot">
                  <div v-if="sendShotStatus == 0">画像を保存</div>
                  <div v-else-if="sendShotStatus == 1" class="loader"></div>
                  <div v-else-if="sendShotStatus == 2">保存しました</div>
                  <div v-else-if="sendShotStatus == 9">再保存する</div>
                </v-btn>
                ※LINEに送られます
              </div>
              <div v-else class="text-left">
                画像を長押しや右クリックで保存できます。
              </div>

            </v-col>


          </v-row>

        </v-card-text>
      </v-card>
    </v-dialog>
    <!---------------------->
  </v-container>
</template>
<style scoped>
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  font-size: 2px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.prephoto {
  margin: 0 auto;
  transform: rotate(5deg);
  border: 3px solid white;
}

.barstyle {
  margin: 0 auto;
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .5;
}

.barstyle:before {
  content: '';
  background: linear-gradient(to right, transparent, #aaa, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}

.barstyle:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: black;
  padding: 0 .5em;
  line-height: 1.5em;
  color: #aaa;
  background-color: #fcfcfa;
}
</style>
<script>
import axios from "axios";
export default {
  data: () => ({
    // viewDialogInfo: false,
    viewDialogShot: false,
    shotCamera: null,
    isArchives: false,
    arcivesBase64: null,
    sendShotStatus: 0,
    isActiveCamera: false
  }),
  created: function () {
    this.viewDialogShot = false;
    this.isArchives = false;
    this.isActiveCamera = false;
  },
  mounted: function () {
    this.$nextTick(function () {
      if (this.$route.params.go) {
        setTimeout(() => {
          this.scrollToAnchorPoint(this.$route.params.go)
        }, 500);
      }
    })
  },

  destroyed: function () {
    this.arcivesBase64 = null;
  },
  methods: {
    async viewShot(count, func) {
      if (!count) count = 1;
      if (this.$refs.capImage) {
        try {
          this.isActiveCamera = false;
          await this.$stamp.shotCamera(this.$refs.capImage, "/images/yunessan_frame.png", () => {
            this.isActiveCamera = true;
          });
        } catch (error) {
          console.error(error);
          this.$router.push({ name: 'error', params: { 'errormessage': "カメラなどのデバイスの利用ができません。" } });
        }
      } else if (count < 5) {
        setTimeout(() => {
          this.viewShot(count + 1, func)
        }, 300);
      }
    },
    switchCamera() {
      this.isActiveCamera = false;
      this.$stamp.switchCamera(() => {
        this.isActiveCamera = true;
      });
    },
    execShot() {
      try {
        this.arcivesBase64 = this.$stamp.getShotCameraData();
        if (!this.arcivesBase64) throw new Error("not image");
        this.sendShotStatus = 0;
        this.isArchives = true;
        this.viewArchiveShot();
        gtag('event', 'photoframe', { 'event_category': 'shot', 'event_label': this.nowMission });
      } catch (error) {
        console.log(error);
      }
    },
    viewArchiveShot(count) {
      if (!count) count = 1;
      if (this.$refs.shotImage) {
        this.$refs.shotImage.src = this.arcivesBase64;
      } else if (count < 5) {
        setTimeout(() => {
          this.viewArchiveShot(count + 1)
        }, 300);
      }
    },
    async saveShot() {
      this.sendShotStatus = 1;
      try {
        gtag('event', 'photoframe', { 'event_category': 'save', 'event_label': this.nowMission });

        let res = await axios.post(
          'https://9o49cj3vk3.execute-api.ap-northeast-1.amazonaws.com/prod/photo',
          // 'https://9o49cj3vk3.execute-api.ap-northeast-1.amazonaws.com/prod/yunessun-stamprally-savephoto',
          {
            'lineUserHashKey': this.$store.getters.lineUserHashKey,
            'localUserId': this.$store.getters.localUserId,
            'image': this.arcivesBase64
          });
        if (res.data.error) {
          throw new Error('not save image');
        }
        await liff.sendMessages([
          {
            type: 'text',
            text: '撮影した写真はこれだよ！'
          },
          {
            "type": "image",
            "originalContentUrl": res.data.path,
            "previewImageUrl": res.data.path
          },
        ])
        this.sendShotStatus = 2;
      } catch (error) {
        this.sendShotStatus = 9;
      }
    },
    scrollToAnchorPoint(refName) {
      const el = this.$refs[refName]
      el.scrollIntoView({ behavior: 'smooth' })
    }
  },
  computed: {
    nowMissionSection() {
      return this.$store.getters.nowMissionSection;
    },
    nowMissionChapter() {
      return this.$store.getters.nowMissionChapter;
    },
    nowMission() {
      return this.$store.getters.nowMissionChapter + "-" + this.$store.getters.nowMissionSection;
    },
    isLineApp() {
      return typeof (this.$store.getters.lineUserHashKey) == "string"
    }
  },
  watch: {
    viewDialogShot(newVal) {
      // console.log("viewDialogShot", this.viewDialogShot)
      if (newVal) {
        this.viewShot();
      } else {
        this.$stamp.stopCamera();
      }
    }
  }
};
</script>

