<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center">
        <img src="/images/yune_title_mission4.png" width="70%" />
      </v-col>
      <v-col cols="12" class="text-left pl-10 pr-10">
        なぜかマイペースな図鑑坊を追ってやってきたのは<span class="text-att">玉座</span>。図鑑坊がくつろいでいる声が聞こえるぞ。
      </v-col>
    </v-row>

    <div class="nazoM" v-if="nowMissionChapter == 4 && nowMissionSection == 1">
      <div style="width:100%">
        <v-row class="text-center ma-0 pa-0">
          <v-col cols="12" class="pb-5" style="font-weight: bold;">
            図鑑坊を見つけ、<br />
            近くのQRを読み取れ
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-btn width="100%" class="btn_orange" @click="viewDialogS1 = true">QRを読む</v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else>
      <v-img src="/images/mission4_tengoku.jpg" style="margin:0 auto;" width="320px" class="mt-5 mb-5"></v-img>
    </div>

    <v-row v-if="nowMissionChapter > 4 || (nowMissionChapter == 4 && nowMissionSection >= 2)">
      <v-col cols="12" class="text-left pl-10 pr-10 pb-0">
        どーーーーん！！！！！どこからか聞こえてくる騒音。<br />
        図鑑坊「大変だ。<span class="text-att">●●●●</span>でお化けが暴れているみたい！今すぐ見に行って！」<br />
        周りの騒ぎで声がよく聞き取れなかった。ん？？これはなんだ？
      </v-col>

      <v-col cols="12" class="text-center pt-0">
        <div class="nazoML">
          <div style="width:100%">
            <v-row class="text-center">
              <v-col cols="12" class="pa-0" style="font-weight: bold;">
                この場所へ行きQRを読め
              </v-col>
              <v-col cols="12" class="pt-7 pb-0">
                <img src="/images/mission4_yutext.png?1" />
              </v-col>
            </v-row>
            <v-row class="text-center" v-if="nowMissionChapter == 4 && nowMissionSection == 2">
              <v-col cols="6" class="pt-3">
                <v-btn width="100%" class="btn_orange" @click="viewDialogS2 = true">QRを読む</v-btn>
              </v-col>
              <v-col cols="6" class="pt-3">
                <v-btn width="100%"
                  :class="$store.getters.useHintList.indexOf(nowMission) == -1 ? 'btn_blue' : 'btn_orange'"
                  @click="s2Hint">ヒントを聞く</v-btn>
              </v-col>
            </v-row>
            <v-row class="text-center pt-5" v-else>
              <v-col class="answerTitle">
                答え
              </v-col>
              <v-col cols="9" class="answerArea">
                緑茶風呂
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="nowMissionChapter > 4 || (nowMissionChapter == 4 && nowMissionSection >= 3)">
      <v-col cols="12" class="text-left pl-10 pr-10 pt-0 pb-0">
        <span class="text-att">緑茶風呂</span>に行くとそこには<span class="text-att">旅する雲梯（うんてい）</span>がいた。こいつが騒音の主なのか…？<br />
        でも、なにも喋らない。一枚の紙を置いて突然どこかへと旅立った。<br />
        おい！待てーー！！！
      </v-col>
      <v-col cols="12">
        <div class="nazoL">
          <div style="width:100%">
            <v-row class="text-center pt-0 pb-0 mb-0">
              <v-col cols="12" style="font-weight: bold;">
                この場所へ行き<br />
                旅する雲梯の<br />
                特技を見つけろ
              </v-col>
              <v-col cols="12" class="pa-0 pt-3">
                <img src="/images/mission4_aotext.png?1" />
              </v-col>
            </v-row>
            <v-row class="text-center pt-0 ma-0" v-if="nowMissionChapter == 4 && nowMissionSection == 3">
              <v-col cols="6" class="pt-0">
                <v-btn width="100%" class="btn_orange" @click="viewDialogS3 = true">答える</v-btn>
              </v-col>
              <v-col cols="6" class="pt-0">
                <v-btn width="100%"
                  :class="$store.getters.useHintList.indexOf(nowMission) == -1 ? 'btn_blue' : 'btn_orange'"
                  @click="s3Hint">ヒントを聞く</v-btn>
              </v-col>
            </v-row>
            <v-row class="text-center pt-0 ma-0" v-else>
              <v-col class="answerTitle">
                答え
              </v-col>
              <v-col cols="9" class="answerArea">
                てんごく飛ばし
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="text-center pa-10">
      <v-btn width="80%" class="btn_orange" @click="$router.push('/map');">エリアマップへ</v-btn>
    </div>

    <!---------------------->

    <QrWindow v-if="viewDialogS1 || viewDialogS2" @close="qrClose" @correct="qrAnswerOk"></QrWindow>

    <InputWindow v-if="viewDialogS3" :answerTexts="['てんごく飛ばし', 'てんごくとばし']" @close="viewDialogS3 = false"
      :hintBtnColor="$store.getters.useHintList.indexOf(nowMission) == -1 ? 'blue' : 'orange'" @hint="s3Hint"
      @correct="s3AnswerOk">
      <template v-slot:question>
        旅する雲梯の特技は?
      </template>
      <template v-slot:hint>
        ヒントを聞く
      </template>
    </InputWindow>

    <!---------------------->

    <HelpWindow :message="hintMessage" @close="hintMessage = null"></HelpWindow>

    <!---------------------->
    <!-- <div v-if="$store.getters.isMaintenance">
      <v-btn @click='$store.commit("setNextMission", 4); $store.commit("setMissionSection", 1)'>1</v-btn>
      <v-btn @click='$store.commit("setNextMission", 4); $store.commit("setMissionSection", 2)'>2</v-btn>
      <v-btn @click='$store.commit("setNextMission", 4); $store.commit("setMissionSection", 3)'>3</v-btn>
      <v-btn @click='missionClear()'>END</v-btn>
    </div> -->

  </v-container>
</template>
<style scoped>
</style>
<script>
import HelpWindow from "./components/HelpWindow";
import InputWindow from "./components/InputWindow";
import QrWindow from "./components/QrWindow";

export default {
  components: {
    HelpWindow,
    InputWindow,
    QrWindow
  },
  data: () => ({
    viewDialogS1: false,
    viewDialogS2: false,
    viewDialogS3: false,
    hintMessage: null
  }),
  created: function () {
    if (this.nowMissionChapter < 4) {
      this.$router.push('/');
    } else if (this.nowMissionChapter == 4 && this.nowMissionSection == 0) {
      this.$router.push('/map');
    }
  },
  destroyed: function () {

  },
  methods: {
    qrAnswerOk() {
      this.qrClose();
      if (this.nowMissionChapter == 4 && this.nowMissionSection == 1) {
        this.$store.commit("setMissionSection", 2);
      } else if (this.nowMissionChapter == 4 && this.nowMissionSection == 2) {
        this.$store.commit("setMissionSection", 3);
      }
    },
    qrClose() {
      this.viewDialogS1 = false;
      this.viewDialogS2 = false;
    },
    s3AnswerOk() {
      this.viewDialogS3 = false;
      this.missionClear();
    },

    missionClear() {
      this.$store.commit("setNextMission", 5);
      this.$router.push({ name: 'book', params: { 'seal': true } });
    },
    s2Hint() {
      this.hintMessage = "湯呑みに入れて飲む、日本っぽいお風呂ってなかったかな？屋内にあるよ。";
    },
    s3Hint() {
      this.hintMessage = "青空とは外のこと。黒い山から流れ落ちるところに黄色い目があるよ。その先を見ると３０秒ごとに繰り返される動きがあるよ！その底を見てみよう！";
    }
  },
  computed: {
    nowMissionChapter() {
      return this.$store.getters.nowMissionChapter;
    },
    nowMissionSection() {
      return this.$store.getters.nowMissionSection;
    },
    nowMission() {
      return this.$store.getters.nowMissionChapter + "-" + this.$store.getters.nowMissionSection;
    }
  },
  watch: {
  }
};
</script>
