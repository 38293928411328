<template>
    <v-container>
        <h1>メンテナンスページ</h1>
        <h2>
            {{ nowMissionChapter }}-{{ nowMissionSection }}
        </h2>
        <hr class="ma-5" />
        <h3>Mission</h3>
        <v-row justify="start">
            <v-col>
                <v-btn @click='$store.commit("setNextMission", 1)'>1</v-btn>
            </v-col>
            <v-col>
                <v-btn @click='$store.commit("setNextMission", 2)'>2</v-btn>
            </v-col>
            <v-col>
                <v-btn @click='$store.commit("setNextMission", 3)'>3</v-btn>
            </v-col>
            <v-col>
                <v-btn @click='$store.commit("setNextMission", 4)'>4</v-btn>
            </v-col>
            <v-col>
                <v-btn @click='$store.commit("setNextMission", 5)'>5</v-btn>
            </v-col>
            <v-col>
                <v-btn @click='$store.commit("setNextMission", 6)'>後日談</v-btn>
            </v-col>
            <v-col>
                <v-btn @click='$store.commit("setNextMission", 9)'>全終了</v-btn>
            </v-col>
        </v-row>

        <hr class="ma-5" />
        <h3>Section</h3>
        <v-row justify="start">
            <v-col>
                <v-btn @click='$store.commit("setMissionSection", 0)'>0</v-btn>
            </v-col>
            <v-col>
                <v-btn @click='$store.commit("setMissionSection", 1)'>1</v-btn>
            </v-col>
            <v-col>
                <v-btn @click='$store.commit("setMissionSection", 2)'>2</v-btn>
            </v-col>
            <v-col>
                <v-btn @click='$store.commit("setMissionSection", 3)'>3</v-btn>
            </v-col>
            <v-col>
                <v-btn @click='$store.commit("setMissionSection", 4)'>4</v-btn>
            </v-col>
            <v-col>
                <v-btn @click='$store.commit("setMissionSection", 5)'>5</v-btn>
            </v-col>
            <v-col>
                <v-btn @click='$store.commit("setMissionSection", 6)'>6</v-btn>
            </v-col>
            <v-col>
                <v-btn @click='$store.commit("setMissionSection", 7)'>7</v-btn>
            </v-col>
        </v-row>

        <hr class="ma-5" />
        <h3>保存データ</h3>
        <v-row justify="start">
            <v-col>
                <v-btn @click='deleteUser'>Del</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>
</style>
<script>
import axios from "axios";

export default {
    data: () => ({
    }),
    created: async function () {
        if (!this.$store.getters.isMaintenance) {
            this.$router.push('/');
            return;
        }
    },
    destroyed: function () {
    },
    methods: {
        deleteUser() {
            const delData = {
                lineUserHashKey: this.$store.getters.lineUserHashKey,
                "deletePassKey": "yunessan delete abcde1234",
                localUserHashKey: this.$store.getters.localUserHashKey
            }
            localStorage.removeItem(this.$store.getters.localStorageKey);
            localStorage.removeItem(this.$store.getters.localStorageKey + "_key");
            axios.delete(
                'https://9o49cj3vk3.execute-api.ap-northeast-1.amazonaws.com/prod/user',
                { data: delData })
                .then((res) => {
                    // console.log("☆ServerRes", res);
                    location.reload(true);
                })
                .catch((error) => {
                    console.error("☆☆ServerERROR", error);
                });
            //https://9o49cj3vk3.execute-api.ap-northeast-1.amazonaws.com/prod/user
        }
    },
    computed: {
        nowMissionChapter() {
            return this.$store.getters.nowMissionChapter;
        },
        nowMissionSection() {
            return this.$store.getters.nowMissionSection;
        },
    },
    watch: {
    }
};
</script>
